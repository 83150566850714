@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.softwar-img {
  width: 100%;
  height: 100vh;
}
.container-fw {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.slider-div {
  height: 35vh;
  overflow: hidden;
}

@media only screen and (min-width: 1378px) {
}

@media only screen and (max-width: 576px) {
  .software-div-full {
    margin-top: 55px;
  }
  .softwar-img {
    /* width: 300px; */
    height: 300px;
  }
}
