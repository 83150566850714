@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.hardware-div {
  /* margin-bottom: 30px; */
  margin-top: 80px;
}

.lottie-3-div {
  height: 574px;
  display: flex;
  align-items: center;
  text-align: center;
}
.lottie-3 {
  /* width: 617px; */
  /* margin-left: 40px; */
  height: 574px;
  /* height: 800px; */
  /* border: 1px solid gray; */
}
.hardware-h2 {
  margin-bottom: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 48px;
  /* margin-top: 30px; */
  /* #404040 */

  color: #404040;
}
.HardRes_hr {
  width: 95%;
  border: 1px solid #1f1f1f;

  /* margin: 0 auto; */
  /* margin-bottom: 2%; */
}

.hardware-p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */
  margin-bottom: 30px;
  /* #404040 */
  /* margin-top: -300px; */
  color: #404040;
}

.hardware-p2 {
  visibility: hidden;
}
.hardware-h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;

  /* #404040 */

  color: #404040;
}
.mobile-hard-view {
  visibility: hidden;
}

/* RESPONSIVE */

@media only screen and (min-width: 1380px) {
  .hardware-h2 {
    /* margin-top: -29px; */
  }
  .hardwareRe-p {
    margin-bottom: 29px;
  }
  .harwareRe-img {
    width: 450px;
    margin-top: 50px;
    margin-left: 155px;
  }
  .lottie-3 {
    /* margin-left: 20px; */
    /* width: 600px; */
    height: 600px;
  }
  .lottie-3-div {
    height: 600px;
    display: flex;
    align-items: center;
    text-align: center;
  }
}

@media only screen and (max-width: 576px) {
  .Web-hard-view {
    visibility: hidden;
    display: none;
  }
  .hardwareRe-p-mobile {
    /* visibility: hidden; */
    margin-bottom: 30px;
    margin-top: -1px;
  }
  .hardware-h2-mobile {
    width: 322px;
    height: 62px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    /* line-height: 130%; */
    /* or 31px */
    visibility: visible;
    /* display: none; */
    /* #404040 */

    color: #131313;
  }
  .HardRes_hr-mobile {
    visibility: visible;
    width: 95%;
    border: 1px solid #1f1f1f;
    margin-top: -35px;
  }
  .hardware-p2 {
    visibility: visible;
    /* margin-top: -190px; */
    text-align: justify;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    /* #404040 */

    color: #404040;
  }
  .lottie-3 {
    margin-left: -80px;
    width: 600px;
    margin-top: -140px;
    /* margin-bottom: 120px; */
  }
  .mobile-hard-view {
    visibility: visible;
    margin-top: -80px;
  }
  .harwareRe-img {
    margin-top: -540px;
    margin-left: 35px;
    width: 300px;
  }
}
