@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

* {
}
#full-div {
  margin: 0 auto;
}
#gallery_page {
  padding-bottom: 3%;
  margin-top: 7%;
}
.gallery_size {
  margin: 0 auto !important;
}
.card-div {
  border-bottom-right-radius: 15px;
}

.galary-h1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 2.2rem;
  color: #333333;
  margin-top: 50px;
  text-align: center;
}
.half_hr {
  width: 15%;
  border: 1px solid #1f1f1f;
  margin: 0 auto;
  margin-bottom: 2%;
}

.img1 {
  width: 100%;
  border-radius: 5px;
}

.img2 {
  width: 98%;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.img-div-slider {
  margin-top: 5px;
}

.word-h5 {
  color: #f0efef;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 30px;
  float: left;
  position: absolute;
  bottom: 35px;
  left: 16px;
  font-size: 18px;
  line-height: 20px;
}
.wordMobile-h5 {
  visibility: hidden;
}

.card-text {
  position: absolute;
  color: aliceblue;
  bottom: -7px;
  left: 16px;
  font-size: 14px;
  display: flex;
}

.single-title1 {
  background: rgba(30, 58, 122, 0.856);
  color: #a7a7a7;
  padding: 3px 3px 2px 7px;

  border-bottom-left-radius: 13px;
}

.single-title2 {
  background: rgba(30, 58, 122, 0.856);
  color: #a7a7a7;
  padding: 3px 3px 2px 3px;
  border-top-right-radius: 13px;
}

/* RESPONSIVE  */
@media only screen and (min-width: 1380px) {
  #full-div {
    width: 1000px;
    margin-left: 450px;
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 576px) {
  #full-div {
    width: 350px;
    margin-left: 30px;
    margin-bottom: 10px;
  }
  .word-h5 {
    visibility: hidden;
  }
  .wordMobile-h5 {
    font-size: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */
    margin-top: 140px;
    color: #ffffff;
    visibility: visible;
  }
  .img-div-slider {
    visibility: hidden;
  }
  .card-div {
    overflow-y: hidden;
  }
}
