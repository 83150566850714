@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.Offer-departments-full-div {
  margin-bottom: 90px;
  position: relative;
  /* padding-bottom: 100px; */
}
.offer-map {
}
.offer-card-div {
  align-items: center;
  text-align: center;

  padding: 20px 0px 40px 0px;
}
.offer-card-div:hover {
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 20px 0px rgb(228, 228, 228);
  cursor: pointer;
  padding: 20px 0px 40px 0px;
}
.offer-h5 {
  text-align: center;
  font-size: 17px;
  font-weight: 800;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  color: #27559e;
}

.offer-h2 {
  text-align: center;
  font-weight: 600;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  color: #282829;
  font-size: 2.2rem;
}
.offerImg1 {
  background: linear-gradient(
    to right top,
    #1e397a,
    #225595,
    #2b71af,
    #3a8ec8,
    #4eacdf
  );
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #efefef;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 15px;
}
.offerImg2 {
  background: linear-gradient(
    to right top,
    #ffc9a1,
    #feb07a,
    #fb9554,
    #f77830,
    #f25700
  );
  border: 1px solid #efefef;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 15px;
}
.offerImg3 {
  background: linear-gradient(
    to right top,
    #a1ffc7,
    #83fdaf,
    #65fa94,
    #43f676,
    #00f252
  );
  border: 1px solid #efefef;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 15px;
}
.offerImg4 {
  background: linear-gradient(
    to right top,
    #a1b0ff,
    #7e90ff,
    #5c6eff,
    #394afb,
    #0018f2
  );
  border: 1px solid #efefef;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 15px;
}
.offers-p {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;

  /* or 150% */
  position: relative;
  display: flex;
  margin: 0px 10%;
  align-items: center;
  cursor: pointer;
  color: #4b4b4b;
}
.home-offer-header {
  font-family: "Poppins", Arial, Helvetica, sans-serif;

  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2.2rem;
  /* identical to box height, or 150% */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1f1f1f;
}
.offer-pText {
  margin: 20px 10px;
}
/* .offer-map {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
} */

/* OfferDepartment */

@media only screen and (min-width: 1378px) {
}
@media only screen and (max-width: 576px) {
  .offer-h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    text-align: center;
    letter-spacing: 0.2em;
  }
  .offer-h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */

    display: flex;
    align-items: center;
    text-align: center;

    color: #12151c;
  }
}
