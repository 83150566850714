@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.hardware-div {
  align-items: center;
  margin-top: 40px;
}
.harware-img {
  margin-left: 130px;
}
.about-banner-text-div {
  /* margin-left: 90px; */
  padding-bottom: 10rem;
  padding-top: 10rem;
}
.text-h1 {
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 25px;
}
.text-h1-span {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 130%;
  /* or 47px */
  align-items: center;
  text-align: justify;
  color: #ffffff;
  padding-top: 170px;
}
.text-p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  /* margin-right: 360px; */
  line-height: 17px;
  text-align: justify;
  color: #ffffff;
}

.about-banner-btn {
  background-color: #ff8e25;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 10px 10px 10px;
  border-radius: 7px;
  border: none;
}
.about-banner-btn:hover{
  background-color: coral;
  color: #ffffff;
}
/* RESPONSIVE */

@media only screen and (min-width: 1380px) {
  .text-h1 {
    padding-top: 250px;
  }
  .about-banner-text-div {
    /* margin-left: 90px; */
    padding-bottom: 10rem;
    margin-top: -260px;
  }
}

@media only screen and (max-width: 576px) {
  .text-h1 {
    width: 269px;
    height: 62px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    margin-top: -40px;
    /* or 31px */
    line-height: 130%;
    color: #ffffff;
  }
  .text-p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 130%;
    margin-top: 130px;
    /* or 16px */

    color: #ffffff;
  }
  .text-h1 {
    color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 300;
    /* margin-bottom: 75px; */
  }
  .text-h1-span {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 130%;
    /* or 47px */
    align-items: center;
    text-align: justify;
    color: #ffffff;
    padding-top: 170px;
  }
}
