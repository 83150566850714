.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgb(30, 57, 122);
  color: #fff;
  border: none;
  border-radius: 30%;
  box-shadow: rgba(110, 110, 110, 0.35) 0px 5px 15px;
  /* width: 100%; */
  padding: 10px;
  cursor: pointer;
}
