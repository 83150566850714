@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap");

body {
  position: relative;
  /* margin: 5px; */
  font-family: "Poppins", Arial, Helvetica, sans-serif;
}
.active {
  border-bottom: 2px solid blue;
}
.activeAbout {
  border-bottom: 2px solid rgb(231, 231, 231);
}
.full-nav {
   box-shadow: 5px 2px 10px 0px rgb(245, 245, 245); 
  /* box-shadow: none; */
}
/*----conditional shadow---------*/
#container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200%;
}
a:hover{
  text-decoration: none;
}


.floatingNav {
  width: calc(100vh - 100px); 
  border-radius: 2px;
  box-shadow: 0px 1px 10px #999;
}


.nav-full-nav {
  margin-bottom: 66px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
}
.nav-item {
 padding: 0 1rem;
  white-space: nowrap;
  /* margin-top: 10px;
  margin-right: 33px; */
}
/* .nav-item a {
  font-family: "Poppins" sans-serif;
  text-decoration: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  color: #1f1f1f;

  text-align: center;
  align-items: center;
} */
.dropdown-menu {
  border: none;
  width: 300px;
  text-align: justify;
  padding-left: 5%;
}
.nav-img {
  width: 160px;
  margin-left: 1rem;
}
#navbarTogglerDemo02 {
  margin-left: 1rem;
}
.btn {
  border-radius: 0.5rem !important;
}
.gap{
  width: 0.5rem;
}
.demo-btn {
  background-color: #ffffff;
  color: #ffff;

  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px !important;
}
.demo-btn:hover {
  background: #292828;
  color: #ffff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px !important;
}
.nav-button button {
  font-size: 13px;
}
.button-que {
  text-decoration: none;
  color: #ffffff;
}
.button-que:hover {
  text-decoration: none;
  color: #ffffff;
}
.button-demo {
  text-decoration: none;
  color: #292828;
}
.button-demo:hover {
  text-decoration: none;
  color: #ffffff;
}

.button1 {
  border-radius: 4px;
  background-color: #1e397a;
  border: none;
  text-align: center;
  padding: 15px 0px 15px 0px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
}

.company-quality{
display:'flex';
align-items:'start';
flex-direction:column;
font-size:12px;
}
.button1::after {
  /* background-color: #1e397a; */
  content: "»";
  color: white;
  position: absolute;
  opacity: 0;
  top: 14px;
  right: -20px;
  transition: 0.3s;
}
.button1:hover {
  /* background-color: #1e397a; */
  color: rgb(250, 250, 250);
  border: none;
  border-radius: 4px;
  padding-right: 24px;
  padding-left: 8px;
}
.button1:hover::after {
  opacity: 1;
  right: 20px;
}

.button2 {
  background-color: white;
  border: 1px solid #d7def0;
  border-radius: 5px;
  font-weight: 600;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  transition: 0.3s;
}
.button2:hover {
  background-color: #1e397a;
  color: white;
  border: 1px solid #1e397a;
}

/* RESPONSIVE  */
/* @media only screen and (min-width: 991px) and (max-width: 1375px) {
  #navbarTogglerDemo02 {
    margin-left: 80px;
  }
} */
/* @media only screen and (min-width: 1380px) {
  .nav-full-nav {
    margin-top: 10px;
  }
  #navbarTogglerDemo02 {
    margin-left: 5rem;
  }
  .nav-img {
    width: 180px;
    margin-left: 160px;
  }
} */

@media only screen and (max-width: 576px) {
  .nav-full-nav {
    margin-top: 30px;
    margin-bottom: 2px;
  }
  .nav-button {
    visibility: hidden;
  }
  .nav-img {
    position: relative;
    margin-left: 5px;
    width: 130px;
  }
  .navbar-toggler {
    margin-right: -1px;
    /* border: 1px solid rgb(236, 236, 236); */
    /* border-radius: 100px; */
    height: 34px;
    color: #808080;
    /* background-color: #c9c9c9; */
  }

  /* .navbar-toggler-icon3 {
    width: 10px;
    height: 8px;
    color: #ced0d6;
  } */
}
