@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.tech-full-div {
  margin-bottom: 60px;
  margin-top: 120px;
}

.tech-h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #27559e;
}

.tech-h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 2.2rem;
  color: #333333;
  text-align: center;
}

.tech-p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #999999;
  text-align: center;
}
.techno-images {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
}

.full-div-cv {
  background-color: #fff7f4;
  padding-bottom: 20px;
  margin-bottom: -40px;
}
.drop-cv-div {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  padding-top: 20px;
}

.Drop-cv {
  margin-right: 10px;
  border: 1px solid blue;
  background-color: #1e397a;
  color: antiquewhite;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
}

.drop-cv-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: #999999;
}

.dropping-input {
  font-size: 13px;
  cursor: pointer;
  margin-right: -20px;
}

.techSlider3 {
  width: 100px;
  height: 80px;
  margin-left: -20px;
}
.techSlider1 {
  width: 80px;
  height: 95px;
}
.techSlider2 {
  margin-top: -15px;
  margin-left: -50px;
  width: 150px;
  height: 120px;
}
.techSlider4 {
  width: 120px;
  height: 95px;
  height: fit-content;
  margin-left: -30px;
}
.techSlider5 {
  width: 100px;
  height: 96px;
  /* margin-left: -30px; */
}

.techSlider7 {
  width: 100px;
  height: 90px;
  margin-left: -5%;
}
.techSlider8 {
  margin-top: 10px;
  width: 130px;
  height: 90px;
  margin-left: -40px;
}
.techSlider9 {
  margin-top: 10px;
  width: 130px;
  height: 85px;
  margin-left: -20px;
}
.techSlider10 {
  margin-top: 10px;
  width: 130px;
  height: 95px;
  margin-left: -20px;
}
.techSlider11 {
  margin-top: 10px;
  width: 120px;
  height: 95px;
  margin-left: -20px;
}
.techSlider12 {
  margin-top: 10px;
  width: 130px;
  height: 95px;
  margin-left: -20px;
}
.techSlider13 {
  margin-top: 10px;
  width: 130px;
  height: 95px;
  margin-left: -20px;
}
.techSlider14 {
  margin-top: 10px;
  width: 150px;
  height: 95px;
  margin-left: -20px;
}
.techSlider15 {
  margin-top: 10px;
  width: 130px;
  height: 95px;
  margin-left: -20px;
}
.techSlider16 {
  margin-top: 10px;
  width: 130px;
  height: 95px;
  margin-left: -20px;
}
.techSlider17 {
  margin-top: 10px;
  width: 130px;
  height: 95px;
  margin-left: -20px;
}

@media only screen and (min-width: 1378px) {
  .techSlider {
    width: 100px;
    display: "grid";
    place-items: "center";
    background-color: "black";
  }
}

@media only screen and (max-width: 576px) {
  .tech-h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    text-align: center;
    letter-spacing: 0.1em;

    /* Text/Body Text */

    color: #666666;
  }
  .tech-h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */

    text-align: center;
    font-feature-settings: "pnum" on, "lnum" on;

    /* Text/Title Text */

    color: #333333;
  }
  .tech-p {
    visibility: hidden;
    display: none;
  }
  .drop-cv-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    text-align: center;

    /* Text/Default in Field */

    color: #999999;
  }
  .drop-cv-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* or 24px */
    padding: 0px 40px;
    text-align: center;

    /* Text/Default in Field */

    color: #999999;
  }
  .Drop-cv {
    margin-bottom: 8px;
  }
  .dropping-input {
    text-align: center;
    margin-left: 66px;
  }
}
