@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.tservice-div {
  align-items: center;
  text-align: center;
  background-color: #fff9f7;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  margin-bottom: 20px;
}
.tservice-div:hover {
  align-items: center;
  text-align: center;
  background-color: #fff9f7;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 0px 20px 0px rgb(228, 228, 228);
}
.tserevices-map {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  overflow-x: hidden;
}
.tservice-p1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */
  margin-top: 20px;
  text-align: center;

  /* Text/Default in Field */

  color: #999999;
}
.tservice-h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  /* or 16px */
  align-items: center;
  text-align: center;
  /* #404040 */
  color: #404040;
  margin-top: -10px;
}
.tservice-p2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */

  text-align: center;

  /* Text/Default in Field */

  color: #999999;
}
.tservice-icon {
  color: #0077b5;
  height: 24px;
  margin-bottom: 2px;
}
.tservice-icon2 {
  color: #ee0024;
  height: 30px;
  width: 25px;
  margin-top: 20px;
}
.team-dropCV {
  margin-top: 30px;
  margin-bottom: 120px;
}

@media only screen and (max-width: 1376px) {
}

@media only screen and (max-width: 576px) {
  .tserevices-map {
    grid-template-columns: repeat(1, 1fr);
    width: 300px;
    margin-left: 28px;
    margin-top: 40px;
  }
}
