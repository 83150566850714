@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.parallax-demo {
  margin-bottom: 2%;
}

.demo-header-texts {
  padding: 100px 0px;
}

.demo-header-h3 {
  font-family: "popins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: center;
  letter-spacing: 0.3px;

  color: #1e397a;
}
.demo-header-h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;
}
.demo-full-div {
  margin: 0 auto;

  width: 60%;
  padding: 5%;
}
.demo-lower-part {
  border: 1px solid rgb(243, 243, 243);
  padding: 5%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 15px;
}
.demo-submit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  height: 47px;
  left: 352px;
  top: 2664px;
  color: rgb(255, 255, 255);
  background-color: #1e397a;
  border-radius: 8px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}
.demo-texT-area {
}

.demo-inpu-text-div {
  margin-bottom: 2%;
}
.demo-inpu-text-p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: justify;
  letter-spacing: 0.3px;

  color: #1e397a;
}
.demo-inpu-text-h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  /* identical to box height */
  text-align: justify;
  color: #1e397a;
}

/* Input  */
.form-select {
  width: 150%;
}
.full-in {
  width: 208%;
}

/* MUI   */
.select-demo {
  width: 100%;
}
.input-demo {
  width: 106%;
}
.input-number-demo {
  width: 103%;
}
.time-demo {
  width: 182%;
}
.date-demo {
  width: 143%;
}
@media only screen and (min-width: 1380px) {
  /* MUI   */
  .select-demo {
    width: 100%;
  }
  .input-demo {
    width: 163%;
  }
  .time-demo {
    width: 275%;
  }
  .date-demo {
    width: 218%;
  }
  .input-number-demo {
    width: 160%;
  }
}

@media only screen and (max-width: 576px) {
  .demo-inpu-text-h3 {
    font-size: 1.2rem;
    display: inline;
    line-height: 1;
  }
  .demo-inpu-text-p {
    font-size: 0.7rem;
    line-height: 1.6;
    display: inline-block;
  }
  .demo-full-div {
    width: 419px;
    margin-left: -14px;
  }
  .select-demo {
    /* width: 110px; */
  }
  .date-demo {
    width: 80%;
  }
  .time-demo {
    width: 100%;
  }
  .demo-submit-button {
    width: 294px;
    margin-left: 16px;
  }
  .demo-lower-full-input-div {
    margin-left: -18px;
    text-align: center;
    display: grid;
    place-content: center;
  }
}
