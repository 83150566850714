@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.full-div-dropping {
  margin-bottom: -100px;
  align-items: center;
  text-align: center;
  background-color: #fff7f4;
  padding-bottom: 20px;
  padding-top: 10px;
  margin-bottom: -38px;
}

.footer-drop-send {
  border: 1px solid #d7def0;
  border-radius: 5px;
  font-weight: 600;
  padding: 14px 27px 14px 27px;
  text-align: center;
  font-size: 16px;
  transition: 0.3s;
  background-color: #1e397a;
  color: rgb(255, 255, 255);
}
.footer-drop-send:hover {
  background-color: white;
  color: rgb(58, 57, 57);
  border: 1px solid #1e397a;
  /* border: none; */
}

.Drop-cv-product {
  margin-right: 10px;
  border: 1px solid blue;
  background-color: #1e397a;
  color: antiquewhite;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
}
.drop-cv-div {
  padding-top: 0px;
}
.drop-cv-text-product {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  margin-top: 16px;
  /* or 36px */
  /* width: 550px; */
  /* Text/Default in Field */
  text-align: justify;
  color: #999999;
}
.dropping-input-product-button {
  border: none;
  /* width: 10px; */
  color: #fff7f4;
  border-radius: 10px;
  margin-right: -300px;
  background-color: #bbac98;
  box-shadow: 0px 0px 15px 0px rgb(206, 205, 205);
}
.dropping-input-product {
  width: 170px;
  cursor: pointer;
}

.footer-full-div {
  position: relative;
  margin-top: 10px;
  align-items: center;
  padding: 60px 0px 20px 80px;
}
.footer-div {
  /* padding-bottom: -100px; */
}
.footer-mobile-div {
  visibility: hidden;
  margin-top: -1300px;
}
.footer-img {
  width: 180px;
  margin-bottom: -40px;
  display: flex;
  align-items: left;
  margin-left: 14px;
}
.connect-footer {
  color: #ffffff;
  font-family: "Inter";
  font-weight: 700;
}
.connect-footer-div {
  text-align: justify;
}
.footer-p1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  text-align: justify;
  margin-left: 15px;
}
.footer-store-imgs {
  width: 10%;
}
.footer-anchor {
  color: #ffffff;
  text-align: justify;
  float: left;
  margin-left: 14px;
}
.footer-anchor:hover {
  color: #bbac98;
}

.address-footer {
  color: #ffffff;
  text-align: start;
  justify-items: start;
}
.privacy-tag-h1 {
  color: #ffffff;
  font-weight: 600;
}
.privacy-anchor {
  text-decoration: none;
  color: #ffffff;
}
.newsletter {
  margin-top: 90px;
  color: #ffffff;
  text-align: justify;
  font-family: "Inter";
  font-weight: 700;
}
.input-footer {
  padding: 12px 10px 12px 10px;
  font-family: "Inter";
  font-weight: 700;
}
.input-footer2 {
  padding: 18px 20px 18px 20px;
  font-family: "Inter";
  font-weight: 700;
}
.footer-input-button {
  background-color: #00e676;
  color: #ffffff;
  border: none;
  padding: 14px 10px 14px 10px;
}

.f-last {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;

  padding: 10px 0px 5px 0px;
  line-height: 150%;
  /* identical to box height, or 24px */
  text-align: center;
  color: #6e6e6e;
}
.mobile-f-last {
  font-size: 13px;
}

/* RESPONSIVE  */
@media only screen and (min-width: 1380px) {
  .footer-div {
    align-items: center;
    text-align: center;
    margin-left: 90px;
  }
  .footer-full-div {
    padding: 60px 0px 1px 140px;
  }

  .drop-cv-div {
    margin-left: 70px;
  }
  .dropping-input-product-button {
    border: none;
    /* width: 10px; */
    color: #fff7f4;
    border-radius: 10px;

    background-color: #bbac98;
    box-shadow: 0px 0px 15px 0px rgb(206, 205, 205);
    /* width: 116px; */
  }
  .dropping-input-product {
    width: 170px;
    cursor: pointer;
    color: #cecece;
  }
}

@media only screen and (max-width: 576px) {
  /* #footer-id {
    margin-right: 10px;
  } */

  .full-div-dropping {
    margin-top: 30px;
  }
  .drop-herediv-product {
    margin-left: 130px;
  }
  .drop-cv-text-product {
    text-align: center;
    /* margin-left: -80px; */
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    text-align: center;

    /* Text/Default in Field */

    color: #999999;
  }

  .dropping-input-product-button {
    margin-left: -300px;
    border: none;
    background-color: #bbac98;
    /* width: 10px; */
    color: #fff7f4;
    border-radius: 10px;
    /* width: 116px; */
    box-shadow: 0px 0px 15px 0px rgb(206, 205, 205);
  }
  .dropping-input-product {
    width: 170px;
    cursor: pointer;
  }

  .footer-mobile-div {
    margin-top: -280%;
    visibility: visible;
    margin-left: -35px;
    margin-bottom: 20%;
  }
  .footer-div {
    visibility: hidden;
  }

  .mobile-text-h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 138% */

    text-align: center;
    font-feature-settings: "pnum" on, "lnum" on;

    /* Text/White */

    color: #ffffff;
  }
  .mobile-imgs {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .footer-mobile-img img {
    width: 190px;
    margin-top: 30px;
  }
  .footer-mobile-img {
    text-align: center;
  }
  .footer-mobile-p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    /* or 18px */
    margin: 10px 10px 0px 0px;
    text-align: center;
    /* Text/White */

    color: #ffffff;
  }
  .footer-store-imgs {
    /* border: 2px solid rgb(255, 255, 255); */
    width: 10%;
  }

  .footer-mobile-anchor {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    /* or 24px */
    text-decoration: none;
    line-height: 24px;
    color: #ffffff;
  }
  .mobile-p-a {
    text-align: center;
  }
  .mobile-view-address {
    color: #ffffff;
  }
  .mobile-address {
    text-align: center;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    /* identical to box height, or 138% */
    margin-top: 30px;
    font-feature-settings: "pnum" on, "lnum" on;

    /* Text/White */
    color: #ffffff;
  }
  .mobile-input-field {
    margin-left: -30px;
  }
  .newsletter2 {
    color: #ffffff;
    text-align: justify;
    font-family: "Inter";
    font-weight: 700;
    text-align: center;
    margin-left: -60px;
  }
  .input-footer2 {
    padding: 14.8px 20px 14.8px 20px;
    font-family: "Inter";
    font-weight: 700;
  }
  .footer-input-button2 {
    background-color: #00e676;
    color: #ffffff;
    border: none;
    padding: 14.3px 18px 14.3px 18px;
  }
  .mobile-f-last {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    text-align: center;

    color: #4b4b4b;
  }
  .f-last {
    padding: 17px 0px 10px 0px;
    /* height: 100%; */
  }
}
