@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.contacts {
  margin-top: 60px;
}
.contact-header-text-div {
  padding: 70px 0px 70px 0px;
}
.contact-p {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 1rem;

  /* identical to box height */
  margin-bottom: -10px;
  color: #ffffff;
}
.address-visit {
  margin-left: 50px;
}
.contact-h1 {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 68px;
  /* identical to box height */

  color: #ffffff;
}
.blogs-input {
  border: none;
  border-bottom: 2px solid gray;
  margin-top: 30px;
  width: 470px;
  background: none;
}

.blogs-input:focus {
  border-bottom: 2px solid gray;
}
.blogs-input-massage {
  border: none;
  background: none;
  border-bottom: 2px solid gray;
  margin-top: 60px;
  width: 470px;
}
.blogs-button {
  margin-top: 20px;
  border: none;
  background-color: #1e397a;
  padding: 7px 210px 7px 210px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;
}
.contact-h6 {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */

  display: flex;
  align-items: center;
  letter-spacing: 0.375em;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #8a9297;
}
.contact-h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  /* identical to box height, or 32px */

  display: flex;
  align-items: center;

  color: #2e3a5c;
}
.contact-icon {
  margin-bottom: 13px;
  color: #8a9297;
}
.contact-p2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  /* or 189% */
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.75);
}
.contact-drop {
  margin-bottom: 130px;
}

/* Responsiveness */

@media only screen and (min-width: 1380px) {
  .address-visit {
    margin-left: 150px;
    margin-top: 20px;
  }
  .contact-input {
    margin-left: -79px;
  }
  .contact-address {
    margin-left: 78px;
  }
}
@media only screen and (max-width: 576px) {
  .contact-p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #ffffff;
  }
  .contact-h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    /* identical to box height, or 32px */
    margin-top: 20px;
    color: #ffffff;
  }
  .contact-input {
    width: fit-content;
    margin-left: -3%;
  }
  .blogs-input {
    width: 360px;
  }
  .blogs-input-massage {
    width: 360px;
  }
  .blogs-button {
    padding: 10px 157px 10px 157px;
    border-radius: 5px;
  }
  .address-visit {
    margin-left: -10px;
  }
  .contact-p2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    color: rgba(0, 0, 0, 0.75);
  }
  .blogs-input {
    width: 330px;
  }
  .blogs-button {
    width: 333px;
  }
}
