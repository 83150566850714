@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.wr-div {
  padding: 120px 0px 120px 0px;
}
.wr-middle-section {
  /* margin-top: 147px; */
}
.wr-text-div {
  /* margin-top: 60px; */
}
.wr-t-h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;
}
.wr-t-h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 68px;
  /* identical to box height */

  color: #ffffff;
}
.work-projects {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 15px;
  /* justify-content: space-evenly; */
}
.work-img {
  margin-left: 15%;
}
.work-h6 {
  margin-left: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 48px;
}
.agile-img-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.agile-img {
  margin-top: -38%;
  margin-bottom: 48%;
  width: 60%;
}
.tag-hr {
  text-align: center;
  margin-top: 60px;
}
.wr-t-h4 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 72px;
  /* identical to box height */

  /* #404040 */

  color: #404040;
}
.WorkRes_hr {
  width: 65%;
  border: 1px solid #1f1f1f;
  /* margin-top: -18px; */
  margin: 0 auto;
  margin-bottom: 2%;
}

.wr-t-p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 130%; */
  /* or 21px */

  /* #404040 */

  color: #404040;
}
.wr-mobile-t-p {
  visibility: hidden;
}
.lottie-div5 {
  /* margin-right: -30px; */
}
.lottie-5 {
  width: 500px;
}

/* Quality Belives  */
.qb-full-div {
  margin-top: -500px;
}
.qb-p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  /* or 21px */

  text-align: center;

  /* #404040 */

  color: #404040;
}
.qb-h2 {
  margin-top: 80px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  /* identical to box height */

  /* #404040 */
  text-align: center;
  color: #404040;
}
.qb-serevices-map {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  overflow-x: hidden;
}

/* Quality Belive  */

.qb-service-div {
  position: relative;
  border: 1px solid rgb(255, 255, 255);
  width: 320px;
  padding: 10px 5px 30px 10px;
  margin: 10px -40px 10px -15px;
  box-shadow: 0px 0px 20px 0px rgb(245, 245, 245);
  border-radius: 5px;
  text-align: center;
}
.qb-service-div:hover {
  position: relative;
  border: 1px solid rgb(248, 248, 248);
  width: 320px;
  padding: 10px 5px 30px 10px;
  margin: 10px -40px 10px -15px;
  box-shadow: 0px 0px 20px 0px rgb(228, 228, 228);
  border-radius: 5px;
  text-align: center;
}
.qb-service-img {
  width: 120px;
  cursor: pointer;
  margin-bottom: 20px;
}
.qb-service-h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  cursor: pointer;
  font-size: 24px;
  color: #404040;
}
.qb-service-p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}
.qbservice-anchor {
  text-decoration: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 167% */
  text-align: center;
  /* #27559E */

  color: #27559e;
}
.mobile-work-projects {
  visibility: hidden;
  /* width: 10px; */
}

/* RESPONSIVE  */

@media only screen and (min-width: 1378px) {
  .lottie-5 {
    width: 570px;
    margin-top: -15px;
  }
  .wr-middle-section {
    margin-top: 37px;
    margin-left: 20px;
  }
  .wr-t-h4 {
    line-height: 36px;
    margin-bottom: 20px;
  }
  .wr-t-p {
    line-height: 21px;
  }
}

@media only screen and (max-width: 576px) {
  .wr-text-div {
    margin-top: 2px;
    visibility: hidden;
  }
  .wr-div {
    margin-top: 60px;
    margin-left: 15px;
  }

  .wr-t-h4 {
    /* border-bottom: 2px solid #d9d9d9; */
    line-height: 45px;
  }
  .wr-t-p {
    visibility: hidden;
  }

  .qb-full-div {
    margin-top: -40%;
  }
  .wr-mobile-t-p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    /* #404040 */
    visibility: visible;
    color: #404040;
  }
  .qb-serevices-map {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
    overflow-x: hidden;
    margin-right: 30px;
  }
  .lottie-5 {
    margin-top: -483px;
    width: 380px;
  }
  .mobile-work-projects {
    visibility: visible;
    margin-top: 26px;
    margin-right: 2rem;
  }
  .work-img {
    margin-left: 20px;
  }
  .work-h6 {
    margin-left: 3%;
    font-size: 16px;
  }
  .work-h6-dedi {
    margin-left: 20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 48px;
  }

  .wr-t-h6 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #ffffff;
  }
  .wr-t-h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    /* identical to box height, or 32px */

    color: #ffffff;
  }

  .agile-img-div {
    margin-top: 50%;
  }
  .agile-img {
    width: fit-content;
  }
}
