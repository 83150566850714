@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.information-div {
  /* margin-top: -500px; */
  /* margin-top: 60px; */
  text-align: center;
  margin-top: -37%;
}
.infor-card-div {
  width: 90% !important;
  margin: 0 auto;
  border: 1px solid rgb(255, 255, 255);
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 2px rgb(223, 221, 221);
  /* margin-right: 4px; */
  /* gap: 10px; */
  /* width: 770px; */
  margin-top: 35px;
  margin-bottom: 30px;
  padding: 18%;
  /* margin-right: 10px; */
}
.infor-card-div:hover {
  border: 1px solid rgb(245, 245, 245);
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 7px rgb(235, 235, 235);
  cursor: pointer;
}
.hardware-h3 {
  /* margin-top: 150px; */
}
.info-img {
  width: 25%;
  height: 20%;
  margin-top: 10px;
  border-radius: 10px;
}
.infor-h4 {
  width: 240.43px;
  height: 28.29px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  /* or 16px */

  display: flex;
  align-items: center;
  text-align: justify;
  /* #404040 */

  color: #404040;
}
.infor-p {
  width: 239.49px;
  height: 71.66px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  /* or 16px */
  text-align: justify;
  /* #404040 */

  color: #404040;
}
.info-anchor {
  margin-left: 66px;
}
.info-anchor a {
  text-align: center;
  align-items: center;
}

/* RESPONSIVE */

@media only screen and (min-width: 1380px) {
  .infor-card-div {
    width: 818px;
  }
  .information-div {
    margin-top: -37%;
  }
  /* .full-infor-card-div {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(3, 1fr);
  } */
}

@media only screen and (max-width: 576px) {
  .full-infor-card-div {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(1, 1fr);
  }
  .information-div {
    margin-top: 30px;
  }
  .information-p1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    /* or 18px */

    /* #404040 */

    color: #404040;
  }
  .info-anchor {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    /* identical to box height, or 167% */
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    margin-left: 30%;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    /* color: #555555; */
  }

  .infor-card-div {
    width: 300px;
    align-items: center;
    border: 1px solid rgb(255, 255, 255);
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 2px rgb(223, 221, 221);
    margin-left: 5%;
    gap: 10px;
    /* width: 770px; */
    margin-top: 35px;
  }
  .infor-card-div:hover {
    width: 300px;
    border: 1px solid rgb(223, 221, 221);
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 30px 7px rgb(235, 235, 235);
    cursor: pointer;
  }
  .hardware-h3 {
    /* margin-right: 10px; */
  }
  .infor-h4 {
    text-align: center;

    /* margin-left: 40px; */
  }
}
