@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Inter:wght@400;600;700&family=Montserrat:wght@400;500;600;700;800&family=Poppins:wght@400;600;800&family=Roboto:wght@400;500;700;900&display=swap");

.vat-pro-slide-text-div {
  /* display: grid;
  place-items: center; */
  /* border: 1px solid gray; */
}
.vat-pro-slide-h1 {
  display: grid;
  place-items: center;
  text-align: center;
  color: #fdfdfd;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  -webkit-text-stroke-color: #aa9f9f;
  -webkit-text-stroke-width: 0.1mm;
}
.cripton-clients {
  width: 40%;
  margin: 0px 0;
}
.Cripton-Clients-text {
  display: flex;
  justify-content: center;
  padding: 20px;
}
.Cripton-Clients-text h3 {
  font-weight: 500;
  font-size: 2.2rem;
  color: #000000;
  font-family: "Inter", sans-serif;
}

.vat-pro-slide-p {
  display: grid;
  place-items: center;
  text-align: center;
  color: #ffffff;
  /* color: #0E2F6E; */
  font-weight: 600;
  font-family: "Inter", sans-serif;
  -webkit-text-stroke-color: #f5f5f5;
  -webkit-text-stroke-width: 0.1mm;
}
.vat-slider-btn-div {
  display: grid;
  place-items: center;
  margin-top: 50px;
}
.vat-slider-btn {
  display: grid;
  place-items: center;
  padding: 10px 70px;
  border: none;
  background-color: #0077b5;
  /* border: 1px solid white; */
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  color: #f1f1f1;
}
.vat-slider-btn:hover {
  background-color: #2c2f30;

  color: #f1f1f1;
}
.vat-pro-div {
  margin-top: 20%;
}

.cripton-icons-alldiv {
  background-color: #f1f1f1;
}
.single-icon-div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  margin: 40px 0;
  padding: 15px;
  width: 100%;

  /* border: 1px solid gray; */
}
.second-text {
  margin-top: 20px;
}
.second-text p {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: flex-start;

  line-height: 0.5;
}
.single-icon-div:hover {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  cursor: pointer;
  border-radius: 10px;
}
.vatpro-icon {
  width: 48px;
  height: 48px;
}
.single-icon-div p {
  font-weight: 600;
  padding-left: 4px;
}

.cripton-third-secton-tag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}
.single-vat-compliance-div {
  margin: 40px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  text-align: justify;
}
.single-vat-compliance-div:hover {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}
.single-vat-compliance-div h6 {
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 0.8rem;
  /* text-align: justify; */
  margin: 5px 0;
}

.cripton-vat-contact-div {
  background-color: #0077b5;
  margin-bottom: -0.8%;
}
.cripton-vat-contact-texts {
  display: grid;
  place-items: center;
  padding: 50px;
}
.cripton-vat-contact-texts h2 {
  color: white;
  font-weight: 800;
  font-family: "Inter", sans-serif;
  font-size: 2.3rem;
}
.cripton-vat-contact-texts p {
  color: white;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 0.9rem;
}

.vat-action-btm {
  padding: 10px 70px;
  background: transparent;
  border: 1px solid white;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  color: #f1f1f1;
}
.vat-action-btm:hover {
  background-color: #160d04;
  border: 1px solid white;

  color: #f1f1f1;
}

.clients {
  background-color: #f1f1f1;
  visibility: hidden;
  display: none;
}
.Cripton-Clients-div{
  display: none;
  visibility: hidden;
}
.slider-review {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.single-review {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: center;
  text-align: center;
  /* border: 1px solid gray; */
  padding-top: 4%;
  width: 40% !important;
  height: 25vh;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  border-radius: 10px;
  margin: 20px auto;
  cursor: pointer;
}

.single-review h4 {
  font-size: 1rem;
  font-weight: 600;
}

/* Responsive */

@media only screen and (min-width: 1380px) {
  .single-icon-div {
    margin-left: 15%;
  }
  .single-vat-compliance-div {
    margin-left: 15%;
  }
}
@media only screen and (max-width: 576px) {
  .vat-pro-slide-h1 {
    font-size: 3rem;
    margin-top: 5%;
  }
  .vat-pro-slide-p {
  }
  .vat-slider-btn {
    margin-top: 90%;
  }
  .cripton-vat-contact-div {
    margin-bottom: -8%;
  }
  .slider {
    margin-left: 30%;
  }
  .slider-review {
    margin-left: 10%;
  }
}
