@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

/* .workimg-div {
  position: relative;
} */
.software-div-banner {
  position: relative;
  z-index: 1;
}

.software-banner {
  width: 100%;
  overflow-x: hidden;
  height: 50vh;
}

.software-mobile-texts-div {
  visibility: hidden;
}

.software-texts-div {
  position: relative;
  z-index: 2;
  border-top-right-radius: 40px;
  margin-top: -35%;
  background-color: #f8f8f8;
  padding: 20px 30px 20px 129px;
  margin-right: 360px;
}

.cripton-Vat {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 47px;
  line-height: 70px;
  /* identical to box height */

  font-feature-settings: "pnum" on, "lnum" on;

  /* #27559E */

  background: linear-gradient(90deg, #235f87 0%, #10084e 103.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.cripton-pro {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 47px;
  line-height: 70px;
  /* identical to box height */

  font-feature-settings: "pnum" on, "lnum" on;

  /* #27559E */

  background: linear-gradient(90deg, #235f87 0%, #10084e 103.44%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.soft-buttons {
  margin-top: 30px;
  display: flex;
  align-items: center;
  text-align: center;
}

.download-file-soft {
  width: 279px;
  height: 47px;
  border-radius: 4px;
  border: none;
  left: 128px;
  top: 753px;
  color: #ffffff;
  background: #1e397a;
  font-size: 14px;
  font-weight: 600px;
}

.soft-button1 {
  border-radius: 4px;
  background-color: #1e397a;
  border: none;
  text-align: center;
  padding: 16px 0px 16px 0px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;

  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;
}

.soft-button1::after {
  /* background-color: #1e397a; */
  content: "»";
  color: white;
  position: absolute;
  opacity: 0;
  top: 14px;
  right: -20px;
  transition: 0.3s;
}

.soft-button1:hover {
  /* background-color: #1e397a; */
  color: rgb(250, 250, 250);
  border: none;
  border-radius: 4px;
  padding-right: 24px;
  padding-left: 8px;
}

.soft-button1:hover::after {
  opacity: 1;
  right: 20px;
}
.software-texts {
  width: 100%;
  text-align: justify;
}

.softwares-h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
  /* identical to box height, or 100% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* #27559E */
  visibility: visible;
  color: #27559e;
}

/* soft-slider  */

.sof-slide {
  width: 95%;
  position: relative;
  /* z-index: -1111; */
}

.inner-overlay-image {
  position: absolute;

  /* bottom: 0px;
  right:0px; */
  /* z-index: 999999; */
  display: inline-block;
}

.inner-overlay-image > img {
  height: 100%;
  position: relative;
  width: 100%;
  bottom: 100px;
  right: 110%;
  height: 150px;
  width: 150px;
  object-fit: contain;
  /* border: 1px solid black; */
  /* background-color: white; */
}

.slick-slide div {
  outline: none;
}

/* .modalcontpreview{
  width: 1200px;
    max-width: 100%;
margin: auto;
} */
.modalcontpreview {
  max-width: 900px;
  margin: 2rem auto;
}
.software-mobile-h2 {
  visibility: hidden;
}

.softwares-p1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 43px;
  /* or 187% */

  /* Text/Title Text */

  color: #333333;
}

.software-span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  /* Text/Default in Field */
  margin-left: 20px;
  color: #999999;
}

.soft-fe-icons {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  /* identical to box height */
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 10px;
  width: 10%;
  padding: 5px 10px;
  color: #ffffff;
  background-color: #1e397a;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
}
.soft-crip-h6 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 10px;
}

.soft-crip-p {
  font-size: 0.9rem;
  margin-left: 10px;
  font-weight: 500;
}

.software-h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  /* or 134% */

  /* Text/Title Text */

  color: #333333;
}

.lottie-Software {
  margin-top: 30%;
  /* margin-right: -25px; */
  /* width: 400px; */
}

.lottie-mobile-Software {
  width: 5px;
  visibility: hidden;
}

.software-slider-h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* Text/Title Text */

  color: #333333;
}

.software-anchor-tag {
  visibility: hidden;
}

.software-slider-p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  /* Text/Title Text */
  margin-top: 10px;
  color: #333333;
}

.software-slider-span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  margin-left: 20px;

  /* Text/Default in Field */

  color: #999999;
}

.software-slider-p2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  text-align: justify;
  /* Text/Body Text */
  margin-right: 20px;
  color: #666666;
}

.software-slid-icon-last {
  margin-right: 18px;
}

.software-h2-summ {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  /* identical to box height, or 133% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* Text/Title Text */

  color: #333333;
}

.software-h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  /* or 42px */

  /* Text/Title Text */

  color: #333333;
}

.software-p2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  text-align: justify;
  /* Text/Body Text */

  color: #666666;
}

.software-span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  /* Text/Default in Field */

  color: #999999;
}

.software-icons-read {
  margin-top: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.software-icons-read a {
  text-decoration: none;
  color: #000000;
  border-bottom: 2px solid black;
  font-weight: 600;
}

.software-drops {
  margin-top: 50px;
  margin-bottom: 120px;
}

.software-lottie-4-div {
  /* margin-left: -10px; */
  /* margin-right: 5px; */
}

.software-4 {
  height: 416px;
}

/* Responsive  */

@media only screen and (min-width: 1380px) {
  .software-texts-div {
    padding: 20px 30px 20px 305px;
    margin-top: -22%;
  }

  .software-post-text-div {
    margin-right: 66px;
  }

  .software-slider-p2 {
    margin-right: 82px;
  }

  .software-icon-last {
    /* margin-right: 20px; */
  }

  .software-slid-icon-last {
    margin-right: 80px;
  }

  .software-drops {
    margin-top: 50px;
    margin-bottom: 120px;
  }

  .software-lottie-4-div {
    margin-left: -90px;
    margin-right: 80px;
  }

  .software-lottie-4 {
    height: 410px;
  }
  .soft-fe-icons {
    margin-left: -90px;
  }
  .soft-crip-h6 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-left: -90px;
  }
  .soft-crip-p {
    font-size: 0.9rem;
    margin-left: -90px;
    font-weight: 500;
  }
}

@media only screen and (max-width: 576px) {
  body {
    overflow-x: hidden;
  }

  .software-rule-img {
    width: 370px;
  }

  .lottie-mobile-Software {
    visibility: visible;
    width: fit-content;
  }

  .lottie-Software {
    visibility: hidden;
    display: none;
  }

  .cripton-Vat-moblie {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    /* line-height: 70px; */
    /* identical to box height */

    font-feature-settings: "pnum" on, "lnum" on;

    /* #27559E */

    background: linear-gradient(90deg, #235f87 0%, #10084e 103.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .cripton-pro-mobile {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 27px;
    /* line-height: 70px; */
    /* identical to box height */

    font-feature-settings: "pnum" on, "lnum" on;

    /* #27559E */

    background: linear-gradient(90deg, #235f87 0%, #10084e 103.44%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  .softwares-p1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    /* or 187% */

    /* Text/Title Text */

    color: #333333;
  }

  .soft-fe-icons {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    /* identical to box height */
    margin-top: 30px;
    margin-bottom: 20px;
    width: 30%;
    padding: 5px 10px;
    color: #ffffff;
    background-color: #1e397a;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
  }

  .software-slider-single {
    margin-left: 8px;
  }

  .software-lottie-4-div {
    visibility: visible;
    /* margin-top: 395px; */
    /* margin-bottom: -140px; */
  }

  .software-lottie-4 {
    width: 370px;
  }

  .software-banner {
    /* margin-left: 10px; */
    height: 300px;
    position: relative;
    z-index: 1;
  }

  .software-icons-read {
    visibility: hidden;
  }

  .software-texts-div {
    margin-top: -20px;
    visibility: hidden;
    display: none;
  }

  .software-mobile-texts-div {
    margin: -120px 10px 0px 10px;
    padding: 20px;
    background-color: #ffffff;
    z-index: 2;
    position: relative;
    border-top-right-radius: 80px;
    visibility: visible;
  }

  .software-mobile-h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    /* identical to box height, or 40px */

    font-feature-settings: "pnum" on, "lnum" on;

    /* #27559E */
    visibility: visible;
    color: #27559e;
  }

  .software-mobile-p1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    /* identical to box height, or 16px */

    /* Text/Title Text */
    margin-top: 17px;
    color: #333333;
  }

  .software-moblie-span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    /* Text/Default in Field */

    margin-left: 20px;
    color: #999999;
  }

  .software-mobile-h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */

    /* Text/Title Text */
  }

  .software-mobile-p2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    /* Text/Body Text */

    color: #666666;
  }

  .software-h2-summ {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    /* identical to box height, or 32px */

    font-feature-settings: "pnum" on, "lnum" on;

    /* Text/Title Text */

    color: #333333;
  }

  /* .works-post-img {
    width: 370px;
    margin-bottom: 17px;
  } */
  .software-h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */

    /* Text/Title Text */

    color: #333333;
  }

  .software-p12 {
    visibility: hidden;
    display: none;
  }

  .software-anchor-tag {
    visibility: visible;
  }
}
