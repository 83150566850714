@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.data-div-banner {
  position: relative;
  z-index: 1;
}
.gap {
  height: 40px;
}
.data-banner {
  width: 100%;
  height: 50vh;
  overflow-x: hidden;
}
.product-icon {
  width: 70px;
  height: 70px;
}

.btn-video {
  color: #1e397a;
  justify-content: center;
  text-align: center;
  background: #efefef;
  border-radius: 4px;
  border: 1px solid #1e397a;
}

.btn-video:hover {
  color: white;
  justify-content: center;
  text-align: center;
  background: #1e397a;
  border-radius: 4px;
  border: 1px solid #1e397a;
}

/* .data-mobile-texts-div {
  visibility: hidden;
}
.data-texts-div {
  position: relative;
  z-index: 2;
  border-top-right-radius: 40px;
  margin-top: -27%;
  background-color: #f8f8f8;
  padding: 1% 8%;
  margin-right: 25%;
} */

.data-head-tag {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 3rem;

  /* identical to box height */

  /* #27559E */

  color: #27559e;
}

.data-p1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 43px;
  /* or 187% */

  /* Text/Title Text */

  color: #333333;
}

.data-p1-span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 43px;
  /* or 187% */

  /* Text/Title Text */

  color: #333333;
}

.data-buttons {
  margin-top: 4%;
  margin-left: 1.6%;
}

.data-download-file-soft {
  width: 279px;
  height: 47px;
  border-radius: 4px;
  border: none;
  /* left: 128px; */
  /* top: 753px; */
  color: #ffffff;
  background: #1e397a;
  font-size: 14px;
  font-weight: 600px;
}

.data-button1 {
  border-radius: 4px;
  background-color: #1e397a;
  border: none;
  text-align: center;
  padding: 15px 0px 15px 0px;
  width: 200px;
  transition: all 0.5s;
  cursor: pointer;

  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;
}

.data-button1::after {
  /* background-color: #1e397a; */
  content: "»";
  color: white;
  position: absolute;
  opacity: 0;
  top: 14px;
  right: -20px;
  transition: 0.3s;
}

.data-button1:hover {
  /* background-color: #1e397a; */
  color: rgb(250, 250, 250);
  border: none;
  border-radius: 4px;
  padding-right: 24px;
  padding-left: 8px;
}

.data-button1:hover::after {
  opacity: 1;
  right: 20px;
}

.data-fe-icons {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  margin-top: 10%;
  /* identical to box height */

  /* width: fit-content; */
  padding: 1%;
  margin-bottom: 4%;

  color: #ffffff;
  background-color: #1e397a;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
  width: fit-content;
}

.fixprop-img {
  height: 200px !important;
  width: 400px !important;
  padding: 3%;
  margin: 0 auto;
}

/* Vertical Tabs Sections 
 */

.full-tab-div {
  margin-bottom: 10%;
}

.vert-tab-carousel {
  margin: 0px 40px;
  border: 1px solid gray;
}

.caros-img {
  width: 200px;
}

.disco-h4 {
  /* background-color: #27559e; */
  font-weight: 700;
  margin-bottom: 0.9rem;
}

/* Inputs  */

.data-inpu-text-div {
  margin-bottom: 140px;
}

.data-inpu-text-p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: center;
  letter-spacing: 0.3px;

  color: #1e397a;
}

.data-inpu-text-h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  /* identical to box height */
  text-align: center;
  color: #1e397a;
}

.data-lower-back-img {
  position: relative;
  z-index: 1;
  /* width: fit-content; */
  background-repeat: no-repeat;
  width: 100%;
  background-size: auto;
  margin-bottom: 15%;
}
.data-lower-part {
  margin-top: 80px;
}
.data-lower-full-input-div {
  width: 67%;
  background-color: #ffffff;
  padding: 50px 30px 20px 30px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
  margin-top: -51%;
  z-index: 2;
  position: relative;
  margin-bottom: 5%;
}

.data-submit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  height: 47px;
  left: 352px;
  top: 2664px;
  color: rgb(255, 255, 255);
  background-color: #1e397a;
  border-radius: 8px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}

.data-center-wrapper {
  position: relative;
  z-index: -1;
}

.data-center-wrapper > img {
  width: 100%;
  max-height: 450px;
  min-height: 420px;
  object-fit: cover;
}


/* Software Solution  */
.cripton-single-img {
  width: 35%;
}

.core-modules-div {
  display: grid;
  place-items: center;
  padding-bottom: 10px;
}
.alumni-connect {
  margin-bottom: 110px;
}
.core-modules-div:hover {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.core-modules-ssp-div:hover {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.core-modules-ssp {
  display: grid;
  place-items: center;
}
.ssp-button {
  display: flex;
  justify-content: center;
  margin-top: 76%;
}
.software-offer-header-div {
  margin-top: 13%;
  margin-bottom: 2%;
  display: grid;
  place-items: center;
}
.software-offer-header {
  background-color: #27559e;
  padding: 10px 40px;
  margin: 0 auto !important;
  color: white;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.see-video-button {
  border: 1px solid #27559e;
  padding: 1% 12%;
  border-radius: 5px;
  font-weight: 600;
  color: #27559e;
  width: 200px;
}

.ssp-video-button {
  border: 1px solid #27559e;
  padding: 1% 12%;
  border-radius: 5px;
  font-weight: 600;
  color: #27559e;
}
.ssp-button {
  /* margin-top: 70%; */
  margin-bottom: 10%;
}
.soft-crip-texts-ssp {
  text-align: center;

  margin-bottom: 13%;
}
.soft-crip-tags {
  color: #333333;
  font-weight: bolder;
  font-size: 1.5rem;
  /* margin-top: 10%; */
}
.soft-crip-tags2 {
  color: #777;
  font-weight: bolder;
  font-size: 1.5rem;
}
.bg_img {
}

.data-mobile-texts-div {
  position: absolute;
  top: 50%;
  z-index: 11;
  width: 75%;
  height: 100%;
  padding: 3% 10%;
  background-color: #f8f9fa;
  border-top-right-radius: 50px;
}

/* Managed Service  */
#managed-service-div {
  margin-top: -7%;
}

@media only screen and (min-width: 1380px) {
  .full-tab-div {
    margin-bottom: 10px;
  }

  .discover-div {
    margin-top: 8%;
  }
  .data-lower-part {
    margin-top: 210px;
  }
  .data-fe-icons {
    margin-left: -23%;
  }

  /* Inputs  */
  .data-input-section {
    /* margin-bottom: 10%; */
  }

  .data-inpu-text-div {
    /* margin-top: 15%; */
    margin-bottom: 140px;
  }

  .data-inpu-text-p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    text-align: center;
    letter-spacing: 0.3px;

    color: #bbc0cc;
  }

  .data-inpu-text-h3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 54px;
    /* identical to box height */
    text-align: center;
    color: #1e397a;
  }

  .data-buttons {
    margin-left: 0.8%;
  }

  .data-lower-back-img {
    position: relative;
    z-index: 1;
    width: 100%;
    background-repeat: repeat;
    background-size: cover;
  }

  .data-lower-full-input-div {
    width: 77%;
    background-color: #ffffff;
    padding: 70px 30px 70px 30px;
    border-radius: 20px;
    margin-top: -53%;
    z-index: 2;
    position: relative;
    margin-bottom: 7%;
  }
  .ssp-button {
    /* margin-top: 70%; */
    margin-bottom: 6%;
  }
  .alumni-text-div {
  }
}

@media only screen and (max-width: 576px) {
  .data-head-tag {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    /* identical to box height */
    line-height: 1.7rem;
    /* #27559E */

    color: #27559e;
  }
  .data-lower-part {
    margin-top: 20px;
  }
  .featured-product-mobile {
    margin-top: 80%;
  }
  .featured-product-text {
    margin-bottom: -20%;
  }
  .core-modules-div {
    margin-top: 10%;
  }

  .data-mobile-texts-div {
    position: absolute;
    top: 50%;
    z-index: 11;
    width: 95%;
    padding-left: 10%;
    /* background-color: white; */
    border-top-right-radius: 50px;
  }

  .data-fe-icons {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 27px;
    margin-top: 105%;
    /* identical to box height */
    visibility: hidden;
    /* width: fit-content; */
    padding: 1%;
    margin-bottom: 2%;

    color: #ffffff;
    background-color: #1e397a;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    text-align: center;
    width: fit-content;
  }

  .data-p1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    /* or 187% */

    /* Text/Title Text */

    color: #333333;
  }

  .data-p1-span {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 43px;
    /* or 187% */

    /* Text/Title Text */

    color: #333333;
  }
  .data-lower-full-input-div {
    width: fit-content;
    margin-bottom: -10px;
  }

  /* Managed Services  */
  #managed-service-div {
    margin-top: -30%;
  }
}

/*-----------------Monitor------------------------*/
#monitor_section {
  /* padding:4em; */
}

.software-solution-img1 {
  height: 150px;
  width: 180px;
}
.img-header-text {
  margin: 0 auto;
}
.software-solution-img1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.software-solution-card-items {
  height: 800px;
  display: flex;
  flex-direction: column;
  position: relative;
}
.software-solution-card-items:hover {
  height: 800px;

  width: 100%;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 3px;
  display: flex;

  flex-direction: column;
  position: relative;
  transition: all 0.1s ease-in;
}
.last-sec-btn {
  position: absolute;
  bottom: 8px;
  left: 30%;
}
