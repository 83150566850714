@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Poppins:wght@400;600;800&display=swap");

body {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}
.slick-dots li button:before {
  color: #fff; /* Set dot color to white */
}
.home-banner-slider {
  margin-top: 12%;
}
.single-slide-h1 {
  display: grid;
  place-items: center;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.single-slide-p {
  display: grid;
  place-items: center;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.single-slide-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.features-learn-more {
  /* margin: 20px;
  color: #ffffff;
  border: 1px solid yellow;
  padding: 10px 40px;
  background-color: #1a1919;
  border-radius: 10px; */
  border: 1px solid yellow;
  padding: 0.9rem 2.9rem;
  margin: 15px 10px;
  background-color: #1a1919;
  color: #ffffff;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.2s ease-out;
  will-change: transform;
  font-size: 15px;
}
.features-learn-more:hover {
  border: 1px solid yellow;
  padding: 0.9rem 2.9rem;
  margin: 15px 10px;
  background-color: #f1bb07;
  color: #ffffff;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.2s ease-out;
  will-change: transform;
  font-size: 15px;
  /* margin: 20px;
  color: #ffffff;
  border: 1px solid yellow;
  padding: 10px 40px;
  background-color: #f1bb07;
  border-radius: 10px; */
}

.Free-demo {
  border: 1px solid yellow;
  padding: 0.9rem 2.9rem;
  margin: 15px 10px;
  background-color: #f1bb07;
  color: #050505;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.2s ease-out;
  will-change: transform;
  font-size: 15px;
}
.Free-demo:hover {
  border: 1px solid yellow;
  padding: 0.9rem 2.9rem;
  margin: 15px 10px;
  background-color: #1a1919;
  color: #ffffff;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: all 0.2s ease-out;
  will-change: transform;
  font-size: 15px;
}

.lottie-1-div {
  margin-left: -70px;
}
.lottie-1 {
  width: 550px;
  /* margin-left: -50px; */
}
.banner-div-full {
  /* margin-top: -80px;
  margin-bottom: -100px; */
  visibility: visible;
  /* display: none; */
}
.home-banner-slider-div {
  visibility: hidden;
  display: none;
}

.banner-div {
  position: relative;
  margin-bottom: 3%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: justify;
  flex-wrap: wrap;
  /* margin-top: 60px; */
}

.banner-img {
}

.input-button {
  display: flex;
}
.home-buttons {
  visibility: hidden;
}

.banner-text-div {
}

.h1-banner {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  color: #404040;
  line-height: 40px;
}

.p-banner {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 0.8rem;
  color: #777777;
}

#inputText {
  background-color: rgb(240, 240, 240);
  width: 360px;
  height: 44px;
}

.banner-search {
  border: none;
  padding: 14px 27px 14px 27px;
  background-color: #27559e;
  color: white;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: 14px;
  border-radius: 4px;
  margin-left: -2px;
}

/* Responsive */

@media only screen and (min-width: 1380px) {
  .home-banner-slider {
    margin-top: 17%;
  }
  .lottie-1 {
    width: 580px;
    margin-right: -80px;
  }
  .banner-text-div {
    margin-left: 1%;
  }
  .lottie-1-div {
    /* margin-left: -70px; */
  }
  .h1-banner {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 39px;
    color: #404040;
    line-height: 50px;
  }
  .p-banner {
    font-size: 13px;
  }
  #inputText {
    background-color: rgb(240, 240, 240);
    width: 520px;
    height: 44px;
  }
}
@media only screen and (max-width: 576px) {
  .home-banner-slider {
    margin-top: 60%;
  }
  .single-slide-h1 {
    font-size: 2.2rem;
  }
  .single-slide-text-div {
    line-height: 20px;
  }
  .features-learn-more {
    /* margin: 20px;
    color: #ffffff;
    border: 1px solid yellow;
    padding: 10px 40px;
    background-color: #1a1919;
    border-radius: 10px; */
    border: 1px solid yellow;
    padding: 0.9rem 2.5rem;
    /* margin: 15px 10px; */
    background-color: #1a1919;
    color: #ffffff;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: all 0.2s ease-out;
    will-change: transform;
    font-size: 0.7rem;
  }
  .features-learn-more:hover {
    border: 1px solid yellow;
    padding: 0.9rem 2.9rem;
    /* margin: 15px 10px; */
    background-color: #f1bb07;
    color: #ffffff;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: all 0.2s ease-out;
    will-change: transform;
    font-size: 0.7rem;
    /* margin: 20px;
    color: #ffffff;
    border: 1px solid yellow;
    padding: 10px 40px;
    background-color: #f1bb07;
    border-radius: 10px; */
  }

  .Free-demo {
    border: 1px solid yellow;
    padding: 0.9rem 2.9rem;
    /* margin: 15px 10px; */
    background-color: #f1bb07;
    color: #050505;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: all 0.2s ease-out;
    will-change: transform;
    font-size: 0.7rem;
  }
  .Free-demo:hover {
    border: 1px solid yellow;
    padding: 0.9rem 2.9rem;
    /* margin: 15px 10px; */
    background-color: #1a1919;
    color: #ffffff;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: all 0.2s ease-out;
    will-change: transform;
    font-size: 0.7rem;
  }
  .banner-div-full {
    overflow-x: hidden;
  }
  .banner-text-div {
    margin-left: -60px;
  }
  .banner-img {
    width: 300px;
  }
  .lottie-1-div {
    margin-top: 30px;
    /* margin-left: -70px; */
  }
  .lottie-1 {
    width: 400px;
    margin-left: 60px;
  }
  .h1-banner {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */
    text-align: center;
    margin-left: 50px;
    /* #404040 */
    color: #404040;
  }
  .p-banner {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 130%;
    /* or 16px */
    text-align: center;
    color: #000000;
    margin-left: 50px;
  }
  .input-button {
    visibility: hidden;
  }
  .banner-button button {
    font-size: 13px;
  }

  .home-buttons {
    margin-top: -50px;
    margin-left: 50px;
    margin-bottom: 60px;
    visibility: visible;
    align-items: center;
  }

  .h-b-button1 {
    border-radius: 4px;
    background-color: #27559e;
    border: none;
    text-align: center;
    padding: 16px 0px 16px 0px;
    width: 220px;
    transition: all 0.5s;
    cursor: pointer;
    box-shadow: 0 10px 20px -8px rgba(170, 170, 170, 0.7);
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    color: #ffffff;
  }
  .h-b-button1::after {
    /* background-color: #1e397a; */
    content: "»";
    color: white;
    position: absolute;
    opacity: 0;
    top: 14px;
    right: -20px;
    transition: 0.3s;
  }
  .h-b-button1:hover {
    /* background-color: #1e397a; */
    color: rgb(250, 250, 250);
    border: none;
    border-radius: 4px;
    padding-right: 24px;
    padding-left: 8px;
  }
  .h-b-button1:hover::after {
    opacity: 1;
    right: 20px;
  }

  .h-b-button2 {
    background-color: white;
    border: 1px solid rgb(201, 201, 201);
    border-radius: 3px;
    font-weight: 600;
    padding: 16px 10px 16px 10px;
  }
  .h-b-button2:hover {
    background-color: #1e397a;
    color: white;
    border: 1px solid #1e397a;
    padding: 16px 10px 16px 10px;
  }
}
