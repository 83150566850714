@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.privacy-policies-div {
  margin-top: 3%;
}
.privacy-h1 {
  font-size: 2.1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin-top: 3%;
}
.privacy-h2 {
  font-size: 1.7rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: rgb(53, 53, 53);
  margin-top: 1.5%;
}
.privacy-h3 {
  font-size: 1.1rem;
  font-family: "Poppins", sans-serif;
  margin-top: 1%;
  font-weight: 600;
}
/* .privacy-anchor {
  text-decoration: none;
  color: black;
} */

/* Google Privacy Policies  */
.google-privacy-policies-div {
  margin-top: 3%;
}
.google-policies-h1 {
  font-size: 2.1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin-top: 3%;
}
.google-policies-h2 {
  font-size: 1.7rem;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: rgb(53, 53, 53);
  margin-top: 1.5%;
}
.google-policies-h3 {
  font-size: 1.1rem;
  font-family: "Poppins", sans-serif;
  margin-top: 1%;
  font-weight: 600;
}
@media only screen and (max-width: 576px) {
  .privacy-policies-div {
    margin-top: 17%;
  }
  .google-privacy-policies-div {
    margin-top: 17%;
  }
}
