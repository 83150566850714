@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

body {
  margin: 0px;
  overflow-x: hidden;
}

#connect_customer_section {
  padding-bottom: 3%;

  background: #efefef;
}
.container-padding {
  padding: 5%;
}
.connect-customer-div {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 0;
  margin-bottom: 15%;
}

.connect-text {
  width: 600px;
}
.CC-Video-div {
  margin: 0 auto;
  background: #fff;
  /* border-radius: 30px; */
  border: #dbdcdc;
  box-shadow: 0 5px 15px rgb(0 0 0 / 15%);
  border-radius: 15px;
  /* background: linear-gradient(
    90deg,
    rgb(97, 97, 97) 0%,
    rgb(248, 248, 248) 35%,
    rgb(211, 241, 247) 100%
  ); */
}
.CC-Video {
  /* width: 100%; */
  /* height: 315px; */
  border-radius: 2%;
  padding: 20px 33px 10px 33px;
}
.ipad-container {
  width: 830px;
  height: 550px;
  margin: 0 auto;
}
.ipad-frame {
  background-color: white;
  width: 100%;
  height: 340px;
  border: 1px solid contour-border;
  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  background-color: screen-bg;
  padding: 35px;
}
.cripton-logos-home-banner-div {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  place-items: center;
  margin-bottom: 5%;
}
.place-center {
  justify-content: center;
  text-align: center;
  align-items: center;
}
.place-center:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  cursor: pointer;
  border-radius: 5px;
}
.cripton-logos-home-banner-div h4 {
  color: #1f1f1f;
  font-weight: 300;
  font-size: 0.8rem;
  text-align: center;
}
.home-product-logo {
  width: 70%;
  padding: 5%;
  margin: 0 auto;
}
.our-key-text {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}
.monitor {
  width: 515px;
  height: 300px;
  overflow-y: hidden;
  border: solid 2em #818181;
  border-radius: 0.5em;
}
.monitor::-webkit-scrollbar {
  width: 25px;
}
.monitor::-webkit-scrollbar-thumb {
  background: #666;
}
::-webkit-scrollbar-track {
  background-color: #c4c4c4;
  border-radius: 10px;
}
.stand:before {
  content: "";
  display: block;
  position: relative;
  background: #222;
  width: 150px;
  height: 50px;
  top: 350px;
  left: 185px;
}
.stand:after {
  content: "";
  display: block;
  position: relative;
  background: #333;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  width: 300px;
  height: 15px;
  top: 50px;
  left: 110px;
}
.youtube-video-i-frames {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 7px;
}

.youtube-video-i-frames::-webkit-scrollbar {
  height: 8px;
  overflow-x: scroll;
  width: auto;
}

.youtube-video-i-frames::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 50px;
  height: 2px;
}
.youtube-thumbnail-images {
  height: 80px;
  width: 130px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 3px;
}

.connect-h2 {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 700;
  text-align: left;
}
.sub-header {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
}
.key-product-crip-logo {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  margin-top: -3%;
  font-size: 1.5rem;
}

.connect-p {
  text-align: justify;
  font-size: 0.8rem;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: normal;
}
.lap-mobile {
  visibility: hidden;
}

/* Background Cover Section  */

.lottie-2 {
  margin-top: -40%;
  /*width: 620px;
  margin-bottom: -60px;
  margin-left: -66px; */
  width: fit-content;
}
#connect-background-cover-div {
  margin-bottom: 100px;
}

.innovation-first-div {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.innovation-first-div {
  text-align: justify;
  padding-top: 0;
}

.innovation-h3 {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 2rem;
  color: #ffff;
}
.innovation2-h3 {
  visibility: hidden;
}
.innovation_sub_header {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  color: #ffff;
  margin-top: -5%;
}

.innovation-p1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  color: #ffffff;
}
.innovation-p2 {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  color: #ffff;
  margin-right: 28%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;

  color: #ffffff;
}

.innovation-text {
  margin-top: 8.6%;
  margin-right: -10%;
}
.innovation-text2 {
  position: sticky;
  text-align: justify;
  margin-left: 180px;
  margin-top: 80px;
  padding-bottom: 130px;
  margin-top: -330px;
}
.innovation-h32 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 32px;
  color: #ffff;
}
.mobile-view-export {
  visibility: hidden;
}

/* RESPONSIVE */

@media only screen and (min-width: 1380px) {
  .lottie-2 {
    width: 90%;
    margin-left: 15%;
  }
  .innovation-text {
    margin-top: 6.3%;
  }
  .innovation-p2 {
    margin-right: 4%;
  }
  .mobile-view-export {
    visibility: hidden;
  }
  .key-product-crip-logo {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    margin-top: 1%;
    font-size: 1.5rem;
  }
  .connect-p {
    font-size: 1rem;
    line-height: 1.8;
  }
  .ipad-container {
    width: 830px;
    height: 550px;
    margin: 0 auto;
  }
  .ipad-frame {
    background-color: white;
    width: 100%;
    height: 420px;
    border: 1px solid contour-border;
    border-radius: 30px;
    margin-bottom: 1%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    background-color: screen-bg;
    padding: 35px;
  }
  .cripton-logos-home-banner-div {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    place-items: center;
    margin-bottom: 5%;
  }
  .place-center {
    justify-content: center;
    text-align: center;
    align-items: center;
  }
  .cripton-logos-home-banner-div h4 {
    color: #1f1f1f;
    font-weight: 300;
    font-size: 0.8rem;
    text-align: center;
  }
  .home-product-logo {
    width: 70%;
    padding: 5%;
    margin: 0 auto;
  }
  .innovation-h3 {
    margin-bottom: 2%s;
  }
}

@media only screen and (max-width: 576px) {
  .CC-Video-div {
    margin-bottom: 30%;
    padding: 1rem 0;
  }
  .connect-customer-div {
    visibility: hidden;
    display: none;
  }
  .lottie-2 {
    margin-top: -200px;
    width: 400px;
    /* margin-bottom: -30px; */
    margin-left: -30px;
  }
  .sub-header {
    font-size: 1rem;
  }
  .cripton-logos-home-banner-div h4 {
    font-size: 0.4rem;
    /* display: inline; */
  }
  .connect-p {
    font-size: 0.7rem;
    line-height: 1.5;
  }
  .ipad-container {
    width: 830px;
    height: 550px;
    margin: 0 auto;
  }
  .ipad-frame {
    background-color: white;
    width: 100%;
    height: 220px;
    border: 1px solid contour-border;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    background-color: screen-bg;
    padding: 20px;
  }
  /* Inovation  */
  .innovation-img {
    width: 400px;
    /* margin-top: -100px; */
  }
  .innovation-h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
    color: #ffffff;
    /* margin-left: 30px; */
  }
  .innovation-text {
    margin: 0 auto;
  }
  .innovation-p1 {
    text-align: center;
    visibility: hidden;
  }
  .innovation2-p1 {
    visibility: hidden;
  }
  .innovation-p2 {
    visibility: hidden;
    margin-top: -45px;
    text-align: center;
    color: #ffffff;
    margin-left: 50px;
  }

  .innovation-button1 {
    align-items: center;
    margin: 0px 50%;
    gap: 4px;
    width: 111px;
    height: 38px;
    left: 130px;
    top: 2177.27px;

    background: #ffffff;
    border-radius: 6px;
  }
  .innovation-button2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 135px;
    gap: 4px;
    width: 111px;
    height: 38px;
    left: 130px;
    top: 2177.27px;

    background: #ffffff;
    border-radius: 6px;
  }
  .innovation-text {
    margin-top: -10px;
  }
  .innovation-text2 {
    margin-left: 10px;
    margin-top: 10px;
  }
  .innovation2-h3 {
    visibility: visible;
    color: #ffffff;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 10px;
  }
  .mobile-view-export {
    visibility: visible;
    display: grid;
    place-items: center;
  }
  .innovation_sub_header {
    visibility: hidden;
  }
  .web-view-export {
    visibility: hidden;
  }
  .innovation-h32 {
    visibility: hidden;
  }
  .innovation2-p2 {
    text-align: center;
    margin-top: -20px;
  }
  .lap-mobile {
    visibility: visible;
    margin-top: 50px;
    width: 100px;
  }
  .lap-mobile img {
    width: 400px;
  }
}
