@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.review-full-div {
  overflow: hidden;
  margin-top: 50px;
}
.ReviewCustomerSlider {
  visibility: hidden;
}

.testimonial-h5 {
  text-align: center;
  position: relative;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: #27559e;
}
.testimonial-h1 {
  position: relative;
  font-size: 2.2rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: #333333;
  text-align: center;
}
.testimonial-p {
  position: relative;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  text-align: center;
  color: #999999;
}
/* Service */
.review-map {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  padding: 30px;
  /* margin-top: 50px; */
  margin-bottom: -160px;
}
.review-div {
  position: relative;

  border: 1px solid rgb(255, 255, 255);
  width: 320px;
  padding: 10px 5px 30px 10px;
  margin: 10px -40px 10px -15px;
  box-shadow: 0px 0px 20px 0px rgb(245, 245, 245);
  border-radius: 5px;
  text-align: center;
}
.review-div:hover {
  position: relative;
  border: 1px solid rgb(248, 248, 248);
  width: 320px;
  padding: 10px 5px 30px 10px;
  margin: 10px -40px 10px -15px;
  box-shadow: 0px 0px 20px 0px rgb(228, 228, 228);
  border-radius: 5px;
  text-align: center;
  transition: 1.7s;
}
.review-img {
  width: 120px;

  cursor: pointer;
  margin-bottom: 20px;
}
.review-img:hover {
  width: 130px;
  cursor: pointer;
  margin-top: -40px;
  transition: 1.7s;
}
.review-h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  cursor: pointer;
  font-size: 24px;
  color: #404040;
  cursor: pointer;
}
.review-p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}

.review-icon {
  color: hsl(61, 90%, 52%);
}

/* RESPONSIVE  */
@media only screen and (min-width: 1380px) {
  .review-div {
    position: relative;

    border: 1px solid rgb(255, 255, 255);
    width: 320px;
    padding: 10px 5px 30px 10px;
    box-shadow: 0px 0px 20px 0px rgb(245, 245, 245);
    border-radius: 5px;
    text-align: center;
  }
  .review-div:hover {
    position: relative;
    border: 1px solid rgb(248, 248, 248);
    width: 320px;
    padding: 10px 5px 30px 10px;
    box-shadow: 0px 0px 20px 0px rgb(228, 228, 228);
    border-radius: 5px;
    text-align: center;
    transition: 1.7s;
  }
  .review-map {
    justify-content: space-between;
  }
}
@media only screen and (max-width: 576px) {
  .testimonial-h5 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 150%;
    /* identical to box height, or 18px */

    text-align: center;
    letter-spacing: 0.1em;

    /* Text/Body Text */

    color: #666666;
  }
  .review-map {
    /* margin-left: -30px; */
    visibility: hidden;
    display: none;
    width: 10px;
  }
  .review-div {
    position: relative;
    border: 1px solid rgb(248, 248, 248);
    width: 330px;
    padding: 10px 5px 30px 18px;
    margin: 20px -40px 10px -10px;
    box-shadow: 0px 0px 20px 0px rgb(228, 228, 228);
    border-radius: 5px;
    text-align: center;
    transition: 1.7s;
  }
  .review-div:hover {
    position: relative;
    border: 1px solid rgb(248, 248, 248);
    width: 320px;
    padding: 10px 5px 30px 10px;
    margin: 20px -40px 10px -10px;
    box-shadow: 0px 0px 20px 0px rgb(228, 228, 228);
    border-radius: 5px;
    text-align: center;
    transition: 1.7s;
  }
  .review-img {
    width: 120px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .review-img:hover {
    width: 130px;
    cursor: pointer;
    margin-top: -40px;
    transition: 1.7s;
  }
  .testimonial-h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */

    text-align: center;
    font-feature-settings: "pnum" on, "lnum" on;

    /* #404040 */

    color: #404040;
  }
  .testimonial-p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* or 18px */

    text-align: center;

    /* Text/Default in Field */

    color: #999999;
  }

  .ReviewCustomerSlider {
    visibility: visible;
    margin-bottom: 80px;
    margin-top: 40px;
  }
  .review-mobile-div {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-top: -600px; */
  }
  .review-mobile-img-text {
    display: grid;
    place-items: center;
  }
  /* .review-mobile-img {
    align-items: center;
  } */
  .review-mobile-p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */
    margin: 0px 40px;
    text-align: center;

    /* Text/Body Text */

    color: #666666;
  }
  .review-mobile-icon {
    color: #fdd835;
    margin: 10px 0px 10px 0px;
  }
  .review-mobile-h6 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    text-align: center;
    font-feature-settings: "pnum" on, "lnum" on;

    /* Text/Title Text */

    color: #333333;
  }
  .review-mobile-p2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    text-align: center;

    /* Text/Body Text */

    color: #666666;
  }
}
