@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&family=Roboto:wght@400;500;700;900&display=swap");

body {
  margin: 0;
  overflow-x: hidden;
}
.rules-full-div {
  margin-bottom: 80px;
  margin-top: -7%;
}
.rules-card-div {
  margin-left: 15px;
  /* box-shadow: 0px 0px 20px 0px rgb(245, 245, 245); */
  /* border: 1px solid rgb(95, 93, 93); */
  /* width: 600px; */
}
.rules-img img {
  width: 340px;
  /* height: 35vh; */
}
.rules-card-text {
  text-align: justify;
  margin-top: 20px;
}
.rules-card-icon {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  margin: 35px 20px 0px 13px;
  justify-content: space-between;
}
.rules-h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #333333;
  /* margin-left: 20px; */
}
.span-date {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  margin-left: 24px;
}

.rules-h4 {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: #333333;
  /* margin-left: 20px; */
}
.rules-p {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #666666;
  margin-right: 30px;
}
.rules-card-icon a {
  color: #169bff;
  margin-left: -15px;
}
.rules-icon {
}

/* RESPONSIVE  */
@media only screen and (min-width: 1378px) {
  .rules-full-div {
    /* padding: 20px; */
  }
}
@media only screen and (max-width: 576px) {
  body {
    overflow-x: hidden;
  }
  .rules-full-div {
    /* margin: 40px 5px 40px 5px; */
    /* margin-left: 20px; */
  }
  .rules-card-div {
    margin-left: 15px;
  }
  .rules-p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */
    margin-right: 40px;
    /* Text/Body Text */

    color: #666666;
  }
  .rules-card-icon a {
    /* margin-left: -34px; */
  }
  .rules-icon {
    margin-right: 10px;
  }
}
