@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.CommunicationIn-fullDiv {
  margin-top: 100px;
  margin-bottom: 80px;
}
.ISO-tag {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
}
.ISO-img {
  width: 122px;
  height: 50px;
  border-radius: 2px;
}
.ISO-h2 {
  /* position: absolute; */
  width: 670px;
  height: 60px;
  left: 492px;
  top: 1874px;
  color: #1e397a;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 60px;
}
.ISO-p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;

  color: #333333;
}
.overviews {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  text-align: center;
}

.overview-icons {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 27px;
  /* identical to box height */
  padding: 5px 10px;
  color: #ffffff;
  background-color: #1e397a;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
}
.overview-icons-num {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  text-align: center;

  color: #000000;
}
.icons-BC {
  /* background-color: #1e397a; */
  display: flex;
  align-items: center;
  width: 100%;
}
.icons-OV {
  /* background-color: #1e397a; */
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.icons-com {
  margin-left: 30px;
  width: 43px;
  width: 43px;
}
.icons-text {
  text-align: justify;
  margin-left: 25px;
}
.icons-BC:hover {
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  cursor: pointer;
  border-radius: 8px;
  text-decoration: none;
}
.icons-h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: -3px;
  color: #000000;
}

.icons-p2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 27px;

  color: #000000;
}
.lottie-commmunication {
  margin-top: 1.3rem;
}
.text-commu {
  margin-bottom: 1.7rem;
}
.text-com-h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  /* identical to box height */
  margin-bottom: -5px;
  color: #1f1f1f;
}
.text-com-p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #777777;
}

/* RESPONSIVE */

@media only screen and (min-width: 1380px) {
}

@media only screen and (max-width: 576px) {
  .ISO-p {
    margin-top: 40%;
  }
  .commu-last-div {
    margin-left: -40px;
  }
}
