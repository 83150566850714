@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

#customize-herder-section {
  margin-top: 6%;
}
.custom-Lottie-div {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: right;
}
.customize-head-text-div {
  position: relative;
  z-index: 2;
  margin-top: -20%;
}

.custom-Lottie {
  width: 60%;
}
.custom-tags-line {
  margin-top: -10%;
}
.customize-p1-div {
  margin-top: 2rem;
  border: 1px solid rgb(200, 200, 253);
  background-color: rgba(30, 57, 122, 0.7);
  opacity: 1;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.13);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  padding: 40px 40px 70px 20px;
  margin-right: 20%;
}
.custom-tag {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;

  /* or 34px */

  color: #1f1f1f;
}
.custom-tag-span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1rem;
  font-size: 36px;

  /* or 34px */

  color: #1f1f1f;
}
.customize-p1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;
  /* or 33px */

  color: #ffffff;
}
.customs-buttons-div {
  margin-top: -5%;
}
.custom-button-demo {
  position: relative;
  width: fit-content;
  background: #1e397a;
  padding: 1.8rem 4.9rem 1.8rem 4.9rem;
  color: #ffffff;
  margin-right: 0.7rem;
  border: none;
  font-size: 1rem;
}
.custom-button-discover {
  border: none;
  position: relative;
  width: fit-content;
  background: #1e397a;
  padding: 1.8rem 6.8rem 1.8rem 6.8rem;
  color: #ffffff;
  font-size: 1rem;
}

.custom-icon {
  width: 20%;
}
.custom-icon-outside {
  width: 7%;
}
.custom-icon-inside-div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 3% 0 3% 0;
}
.custom-icon-outside-div {
  display: grid;
  place-items: center;
}
.custom-icons-h6 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 106%;
  /* or 23px */

  text-align: center;
  margin-bottom: -2px;
  color: #000000;
}
.custom-icons-p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 106%;
  /* or 23px */

  text-align: center;

  color: #000000;
}
.custom-button-demo-lower-div {
  margin-top: 6%;
  display: grid;
  place-items: center;
}
.custom-button-demo-lower {
  position: relative;

  width: fit-content;
  background: #1e397a;
  padding: 1rem 2rem 1rem 2rem;
  color: #ffffff;
  margin-right: 0.7rem;
  border: none;
  font-size: 1rem;
}

/* RESPONSIVE */

@media only screen and (min-width: 1380px) {
}

@media only screen and (max-width: 576px) {
  #customize-herder-section {
    margin-top: 20%;
  }
  .customize-p1-div {
    width: 400px;
    margin-left: -20px;
  }
  .customize-p1 {
    font-size: 0.8rem;
    text-align: justify;
  }
  .customs-buttons-div {
    display: grid;
    place-items: center;
  }
  .custom-button-demo {
    width: 330px;
    font-size: 0.8rem;
    padding: 20px 20px;
    margin-bottom: 2%;
    border-radius: 5px;
  }
  .custom-button-demo:hover {
    background-color: #2b2323;
    color: #ffffff;
  }
  .custom-button-discover {
    width: 330px;
    font-size: 0.8rem;
    border-radius: 5px;
    padding: 20px 20px;
    margin-bottom: 2%;
    margin-right: 3%;
  }
  .custom-button-discover:hover {
    background-color: #2b2323;
    color: #ffffff;
  }
}
