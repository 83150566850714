@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.team-header-texts {
  padding: 125px 0px 125px 0px;
}
.team-header-h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 68px;
  /* identical to box height */

  color: #ffffff;
}
.team-header-h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;
}
