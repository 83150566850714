@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.parallax-qurties {
  margin-bottom: 2%;
}


.quarties-header-texts {
  padding: 100px 0px;
}

@media only screen and (max-width: 576px) {
 
.quarties-header-texts {
  padding: 100px 0px;
}

}
.quarties-full-div {
  margin: 0 auto;

  width: 60%;
  padding: 5%;
}
.ques-lower-part {
  border: 1px solid rgb(243, 243, 243);
  padding: 5%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 15px;
}
.ques-submit-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  height: 47px;
  left: 352px;
  top: 2664px;
  color: rgb(255, 255, 255);
  background-color: #1e397a;
  border-radius: 8px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}
.ques-texT-area {
}

.ques-inpu-text-div {
  margin-bottom: 2%;
}
.ques-inpu-text-p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: center;
  letter-spacing: 0.3px;

  color: #1e397a;
}
.ques-inpu-text-h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  /* identical to box height */
  text-align: center;
  color: #1e397a;
}
.team-header-h6 {
  margin-top: 4%;
}

@media only screen and (max-width: 576px) {
  .quarties-header-texts {
  }
  .team-header-h6 {
    font-size: 1.3rem;
  }
  .team-header-h3 {
    font-size: 2.4rem;
  }
  .ques-inpu-text-h3 {
    line-height: 1;
    font-size: 1.4rem;
  }
  .ques-lower-part {
    width: 380px;
    margin-left: -47%;
  }
  .ques-inpu-text-p {
    font-size: 0.7rem;
    line-height: 1.3;
  }
}
