@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

#surveillance-herder-section {
  margin-top: -3%;
}
.surveillance-Lottie-div {
  display: flex;
  justify-content: right;
  position: relative;
  z-index: 1;
  margin-top: 8%;
}
.surveillance-Lottie {
  width: 60%;
}
.surveillance-head-text-div {
  position: relative;
  z-index: 2;
  margin-top: -30%;
}
.surveillance-tags-line {
  margin-top: -2rem;
}
.surveillance-p1-div {
  margin-top: 2rem;

  background-color: rgba(30, 57, 122, 0.7);
  opacity: 1;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.13);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  padding: 40px 40px 70px 20px;
  margin-right: 20%;
}
.surveillance-tag {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;

  /* or 34px */

  color: #1f1f1f;
}
.surveillance-tag-span {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1rem;
  font-size: 36px;

  /* or 34px */

  color: #1f1f1f;
}

.modal-width .modal-dialog {
  width: 100%;
  max-width: 50%;
}
.surveillance-p1 {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: lighter;
  font-size: 1rem;
  color: #ffffff;
}
.surveillance-buttons-div {
  margin-top: -3.5%;
}
.surveillance-button-demo {
  position: relative;
  width: fit-content;
  background: #1e397a;
  padding: 1.4rem 4.9rem 1.4rem 4.9rem;
  color: #ffffff;
  margin-right: 0.7rem;
  border: none;
  font-size: 1rem;
  border-radius: 8px;
}
.surveillance-button-discover {
  border: none;
  position: relative;
  width: fit-content;
  background: #1e397a;
  padding: 1.4rem 6.8rem 1.4rem 6.8rem;
  color: #ffffff;
  font-size: 1rem;
  border-radius: 8px;
}
.btn-ptvl-color {
  background: #ffff;
  border-radius: 8px;
  border: 1px solid #1e397a;
  color: #1e397a;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
}
.btn-ptvl-color:hover {
  background: #1e397a;
  color: white;
}

/* tabs-mui */
.software_solution_page #tabs-mui {
  /* margin-top: 7%;*/
  /* margin-bottom: 70%; */
  /* border: 1px solid gray; */
  min-height: 500px;
  margin: 5% 0;
}

.tab-cams-div {
  display: flex;
  justify-content: space-evenly;
}
.tabs-cams {
  width: 30%;
  border-radius: 5px;
  /* height: 10%; */
}
.survillence-broch-down-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.survillence-broch-down {
  background-color: #1e397a;
  color: #ffffff;
  padding: 5px 20px 5px 20px;
  border: none;
  border-radius: 4px;
}

.surveillance-button-demo:hover,
.surveillance-button-discover:hover,
.survillence-broch-down:hover {
  background: #1f1f1f;
  color: wheat;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

/*----------animation------------------*/
@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    -ms-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

.fa-download:hover {
  cursor: pointer;
  animation-name: bounce;
  -moz-animation-name: bounce;
  -webkit-animation: bounce 2s infinite linear;
  -moz-animation: bounce 2s infinite linear;
  -o-animation: bounce 2s infinite linear;
  animation: bounce 2s infinite linear;
}

.software-paralla-div1 {
}
.software-lottie-softjson1-full-div2 {
  /* display: flex;
  justify-content: space-evenly;
  align-items: center; */
  position: relative;
  z-index: 2;
  /* margin-top: -30%; */
  /* border: 1px solid gray; */
  /* margin-left: 10%; */
}
.software-paralla {
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}

.soft-lower-Lottie {
  width: 50%;
  /* padding: 30px 0px 30px 0px; */
  /* margin-top: -20%; */
  /* margin: 0 auto; */
  /* z-index: 2; */
}
.lower-tag-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 57px;

  color: #000000;
}
.s {
  display: flex;
}
.check12 {
  position: relative;
  /* z-index: -1; */
}
.soft-lower-Lottie {
  position: absolute;
  top: -40%;
  /* right: 0; */
  z-index: 9999;
  border: 1px solid red;
}
.soft-get-appoin-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.soft-get-appoin-btn {
  padding: 15px 30px;
  border: none;
  background-color: #1e397a;
  color: #ffffff;
  font-size: 1rem;
  margin-top: -2%;
  z-index: 2;
  border-radius: 4px;
}
.soft-get-appoin-btn:hover {
  background-color: #1f1f1f;
  cursor: pointer;
  color: antiquewhite;
}

.See-more-btn {
  display: flex;
  justify-content: center;
  /* border: 1px solid gray; */
  width: 100%;
}

/* //rakib code // */

.custom-software-solu {
  position: relative;
  height: 300px;
  z-index: -1;
}
.custom-software-solu > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.left-rounded-img {
  position: absolute;
  left: 5%;
  top: -40%;
  z-index: 11;
  width: 240px;
}
.left-rounded-img > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.content-overlayss1 {
  position: absolute;
  z-index: 100;
  right: 0;
  left: 10;
  top: 50%;
  width: 50%;
}

.button-apt {
  width: 300px;
  background-color: #1e397a;
  position: absolute;
  bottom: -10%;
  left: 40%;
}

.icons-style {
  width: 20px;
  height: 30px;
  object-fit: contain;
}
@media only screen and (max-width: 576px) {
  .modal-width .modal-dialog {
    /* width:100%; */
    max-width: 100%;
  }
}
