@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

#surveillance-herder-section {
  margin-top: -3%;
}
.surveillance-Lottie-div {
  display: flex;
  justify-content: right;
  position: relative;
  z-index: 1;
}
.surveillance-Lottie {
   width: 60%; 
}
.surveillance-head-text-div {
  position: relative;
  z-index: 2;
  margin-top: -20%;
}
.surveillance-tags-line {
  margin-top: -10%;
}
.surveillance-p1-div {
  margin-top: 2rem;
 
  background-color: rgba(30, 57, 122, 0.7);
  opacity: 1;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.13);
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  padding: 40px 40px 70px 20px;
  margin-right: 20%;
}
.surveillance-tag {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;

  /* or 34px */

  color: #1f1f1f;
}
.surveillance-tag-span {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 1rem;
  font-size: 36px;

  /* or 34px */

  color: #1f1f1f;
}
.surveillance-p1 {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: lighter;
    font-size: 1rem;
    color: #ffffff;
}
.surveillance-buttons-div {
  margin-top: -3.5%;
}
.surveillance-button-demo {
  position: relative;
  width: fit-content;
  background: #1e397a;
  padding: 1.4rem 4.9rem 1.4rem 4.9rem;
  color: #ffffff;
  margin-right: 0.7rem;
  border: none;
  font-size: 1rem;
  border-radius: 8px;
}
.surveillance-button-discover {
  border: none;
  position: relative;
  width: fit-content;
  background: #1e397a;
  padding: 1.4rem 6.8rem 1.4rem 6.8rem;
  color: #ffffff;
  font-size: 1rem;
  border-radius: 8px;
}

/* tabs-mui */
#tabs-mui {
  /* margin-top: 7%;
  margin-bottom: 10%; */
}
.tabs-mui-securtiy{
  
}

.tab-cams-div {
  display: flex;
  justify-content: space-evenly;
}
.tabs-cams {
  width: 30%;
  border-radius: 5px;
  /* height: 10%; */
}
.survillence-broch-down-div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.survillence-broch-down {
  background-color: #1e397a;
  color: #ffffff;
  padding: 5px 20px 5px 20px;
  border: none;
  border-radius: 4px;
}

.surveillance-button-demo:hover, .surveillance-button-discover:hover, .survillence-broch-down:hover  {
  background: #1f1f1f;
  color: wheat;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
} 


/*----------animation------------------*/
@keyframes bounce {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-20px);
		-ms-transform:     translateY(-20px);
		transform:         translateY(-20px)
	}
	60% {
		-webkit-transform: translateY(-15px);
		-ms-transform:     translateY(-15px);
		transform:         translateY(-15px)
	}
}


.fa-download:hover {
  cursor: pointer;
  animation-name: bounce;
  -moz-animation-name: bounce;
  -webkit-animation: bounce 2s infinite linear;
  -moz-animation: bounce 2s infinite linear;
  -o-animation: bounce 2s infinite linear;
  animation: bounce 2s infinite linear;

}