@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

/* Services */
.services-div {
  margin-bottom: 7%;
}

.services-h1 {
  font-weight: 600;
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  color: #353535;
  margin-bottom: 1%;
  text-align: center;
  font-size: 2.2rem;
}
.serevices-map {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  overflow-x: hidden;
}
.half_hr {
  width: 15%;
  border: 1px solid #1f1f1f;
  margin: 0 auto;
  margin-bottom: 2%;
}

/* Service */
.service-div {
  /* position: relative; */

  /* width: 220px; */
  /* padding: 10px 5px 30px 10px; */
  /* min-width: 300px; */
  /* width: 20vw; */
  /* box-shadow: 0px 0px 20px 0px rgb(245, 245, 245); */
  /* border-radius: 5px; */
  /* text-align: left; */
  text-decoration: none;
}
.service-div:hover {
  box-shadow: 0px 0px 20px 0px rgb(228, 228, 228);
  border-radius: 5px;
  /* padding: 10px 5px 30px 10px; */
  text-decoration: none;
}
.service-img {
  /* width: 120px; */
  border-radius: 8%;
  cursor: pointer;
  margin-bottom: 20px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  padding: 2%;
  /* border: 1px solid rgb(151, 151, 151); */
  width: 65px;
  height: 65px;
  background: white;
}
.service-h6 {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 700;
  cursor: pointer;
  font-size: 24px;
  color: #333333;
}
.service-p {
  font-family: "Poppins", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  color: #666666;
}

/* RESPONSIVE  */
@media only screen and (min-width: 1380px) {
  .serevices-map {
    /* display: grid;
    grid-template-columns: repeat(3, 1fr); */
    /* place-items: center; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    overflow-x: hidden;
  }
  .service-div {
    position: relative;

    /* padding: 10px 5px 30px 10px; */
    margin-top: 27px;
    width: 350px;
    box-shadow: 0px 0px 20px 0px rgb(245, 245, 245);
    border-radius: 5px;
    text-align: left;
  }
  .service-div:hover {
    position: relative;

    /* padding: 10px 5px 30px 10px; */
    width: 350px;
    box-shadow: 0px 0px 20px 0px rgb(228, 228, 228);
    border-radius: 5px;
    text-align: left;
  }
}
@media only screen and (max-width: 576px) {
  .serevices-map {
    display: flex;
    flex-wrap: wrap;
    overflow-x: hidden;
    margin-top: 60px;
  }
  .services-div {
  }
  .service-div:hover {
    position: relative;

    width: 300px;
    /* padding: 10px 5px 30px 10px; */
    margin-top: 27px;
    box-shadow: 0px 0px 20px 0px rgb(228, 228, 228);
    border-radius: 5px;
    text-align: left;
  }

  .service-img {
    width: 100px;
    height: 100px;
  }
  .service-h6 {
    font-family: "Poppins", Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    /* identical to box height */

    /* #404040 */

    color: #404040;
  }
  .service-p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    /* or 24px */

    text-align: center;

    /* Text/Body Text */

    color: #666666;
  }
  .services-h1 {
    /* visibility: hidden; */
    margin-top: 25%;
  }
  .serevices-map {
    margin-right: 30px;
    /* margin-top: -80px; */
  }
  .service-div {
    text-align: center;
    cursor: pointer;
  }
  .service-div:hover {
    text-align: center;
  }
}
