@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

.valu-h5 {
  position: relative;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: #666666;
  text-align: center;

  color: #27559e;
}
.valu-h1 {
  position: relative;
  font-size: 40px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  color: #333333;
  text-align: center;
  font-size: 2.2rem;
}
.valu-p {
  position: relative;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  text-align: center;
  color: #999999;
  margin-bottom: 20px;
}
.clients-imgs {
  width: 150px;
  height: 150px;
}

/* RESPONSIVE  */
@media only screen and (max-width: 576px) {
  .valu-h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.1em;

    /* Text/Body Text */
    color: #666666;
  }
  .valu-h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 130%;
    /* identical to box height, or 26px */

    text-align: center;
    font-feature-settings: "pnum" on, "lnum" on;

    /* Text/Title Text */

    color: #333333;
  }
  .valu-p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    /* or 18px */

    text-align: center;

    /* Text/Default in Field */

    color: #999999;
  }
}
