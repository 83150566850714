/* Responsive  */
/* @media only screen and (min-width: 1380px) {
  * {
    overflow-x: hidden;
  }
}

@media only screen and (max-width: 576px) {
  * {
    overflow-x: hidden;
  }
} */
body {
  background-color: #f8f8f8;
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.main_product_page h3 {
  font-weight: bolder;
  font-size: 1.5rem;

  color: #1e397a;
}
.half-hr {
  margin: 0 auto !important;
  width: 50%;
  align-content: center;
  text-align: center;
  vertical-align: middle;
  padding: 1% 0;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.swal2-popup {
  font-size: 14px !important;
  font-family: Georgia, serif;
  width: 30% !important;
}
