@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;800&display=swap");

/* .workimg-div {
  position: relative;
} */
.works-div-banner {
  position: relative;
  z-index: 1;
}
.works-banner {
  width: 100%;
  overflow-x: hidden;
}
.works-mobile-texts-div {
  visibility: hidden;
}
.works-texts-div {
  position: relative;
  z-index: 2;
  border-top-right-radius: 40px;
  margin-top: -340px;
  background-color: rgb(255, 255, 255);
  padding: 20px 30px 20px 129px;
  margin-right: 360px;
}
.blogs-texts {
  width: 100%;
  text-align: justify;
}
.blog-h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 64px;
  /* identical to box height, or 100% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* #27559E */
  visibility: visible;
  color: #27559e;
}
.blog-mobile-h2 {
  visibility: hidden;
}
.blog-p1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  /* identical to box height, or 21px */
  margin-top: 20px;
  /* Text/Title Text */

  color: #333333;
}
.blog-span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  /* Text/Default in Field */
  margin-left: 20px;
  color: #999999;
}
.blog-h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 43px;
  /* or 134% */

  /* Text/Title Text */

  color: #333333;
}
.blogs-slider-h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* or 160% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* Text/Title Text */

  color: #333333;
}
.blog-anchor-tag {
  visibility: hidden;
}
.blogs-slider-p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  /* Text/Title Text */
  margin-top: 10px;
  color: #333333;
}
.blogs-slider-span {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */
  margin-left: 20px;

  /* Text/Default in Field */

  color: #999999;
}
.blogs-slider-p2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  text-align: justify;
  /* Text/Body Text */
  margin-right: 20px;
  color: #666666;
}
.blog-slid-icon-last {
  margin-right: 18px;
}

.works-h2-summ {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 64px;
  /* identical to box height, or 133% */

  font-feature-settings: "pnum" on, "lnum" on;

  /* Text/Title Text */

  color: #333333;
}
.works-h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  /* or 42px */

  /* Text/Title Text */

  color: #333333;
}
.works-p2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  text-align: justify;
  /* Text/Body Text */

  color: #666666;
}
.works-p1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  /* Text/Title Text */

  color: #333333;
}
.works-span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  /* identical to box height, or 18px */

  /* Text/Default in Field */

  color: #999999;
}
.works-icons-read {
  margin-top: 38px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.works-icons-read a {
  text-decoration: none;
  color: #000000;
  border-bottom: 2px solid black;
  font-weight: 600;
}

.works-drops {
  margin-top: 50px;
  margin-bottom: 120px;
}
.lottie-4-div {
  /* margin-left: -10px; */
  /* margin-right: 5px; */
}
.lottie-4 {
  height: 416px;
}

/* Responsive  */

@media only screen and (min-width: 1380px) {
  .works-texts-div {
    padding: 20px 30px 20px 305px;
  }
  .works-post-text-div {
    margin-right: 66px;
  }
  .blogs-slider-p2 {
    margin-right: 82px;
  }
  .icon-last {
    /* margin-right: 20px; */
  }
  .blog-slid-icon-last {
    margin-right: 80px;
  }
  .works-drops {
    margin-top: 50px;
    margin-bottom: 120px;
  }
  .lottie-4-div {
    margin-left: -90px;
    margin-right: 80px;
  }
  .lottie-4 {
    height: 410px;
  }
}
@media only screen and (max-width: 576px) {
  body {
    overflow-x: hidden;
  }
  .blog-rule-img {
    width: 370px;
  }
  .blogs-slider-single {
    margin-left: 8px;
  }
  .lottie-4-div {
    visibility: visible;
    /* margin-top: 395px; */
    /* margin-bottom: -140px; */
  }
  .lottie-4 {
    width: 370px;
  }

  .works-banner {
    /* margin-left: 10px; */
    height: 300px;
    position: relative;
    z-index: 1;
  }
  .works-icons-read {
    visibility: hidden;
  }
  .works-texts-div {
    margin-top: -20px;
    visibility: hidden;
    display: none;
  }
  .works-mobile-texts-div {
    margin: -120px 10px 0px 10px;
    padding: 20px;
    background-color: #ffffff;
    z-index: 2;
    position: relative;
    border-top-right-radius: 80px;
    visibility: visible;
  }
  .blog-mobile-h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    /* identical to box height, or 40px */

    font-feature-settings: "pnum" on, "lnum" on;

    /* #27559E */
    visibility: visible;
    color: #27559e;
  }
  .blog-mobile-p1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 130%;
    /* identical to box height, or 16px */

    /* Text/Title Text */
    margin-top: 17px;
    color: #333333;
  }
  .blog-moblie-span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    /* Text/Default in Field */

    margin-left: 20px;
    color: #999999;
  }
  .blog-mobile-h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */

    /* Text/Title Text */
  }
  .blog-mobile-p2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    /* or 21px */

    /* Text/Body Text */

    color: #666666;
  }
  .works-h2-summ {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    /* identical to box height, or 32px */

    font-feature-settings: "pnum" on, "lnum" on;

    /* Text/Title Text */

    color: #333333;
  }
  /* .works-post-img {
    width: 370px;
    margin-bottom: 17px;
  } */
  .works-h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    /* or 26px */

    /* Text/Title Text */

    color: #333333;
  }
  .works-p12 {
    visibility: hidden;
    display: none;
  }
  .blog-anchor-tag {
    visibility: visible;
  }
}
